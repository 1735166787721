//D-5,SD-5
@if $type == sp{
	.formTitleArea{
		.btn_arrow_beige{
			margin: 0 10px 10px;
		}
	}
	.btn_arrow_beige{
		font-size: 14px;
		position: relative;
		padding-right: 15px;
		&:before{
			display: block;
			position: absolute;
			width: 10px;
			height: 10px;
			margin-top: -6px;
			top: 50%;
			right: 0px;
			border-top: solid 1px #a39679;
			border-right: solid 1px #a39679;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
		}
	}

	.cartListWrap{
		margin: 0 10px 15px;
		.cartListOne{
			margin-bottom: 30px;
			border:1px solid #cccccc;
			box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
		}
		.col_info{
			.col_info_in{
				clear: both;
			}
			.shopLabel{
				background-color: #ece9e2;
				padding: 10px 5px;
				margin-bottom: 5px;
			}
			.img{
				text-align: center;
				margin: 10px 5px;
				float: left;
				width: 100px;
			}
			.infoIn{
				margin: 10px 5px 15px 10px;
			}
		}
		.col_select{
			margin: 10px 5px;
			.col_select_in dl{
				display: inline-block;
				width: 49%;
				margin-bottom: 5px;
				dt{
					font-size: 12px;
				}
				dd{
					select{
						width: 100%;
					}
				}
			}
		}
		.col_total{
			clear: both;
			position: relative;
			margin: 10px 5px;
			.price{
				display: inline-block;
				font-size: 16px;
				margin-right: 5px;
				padding-left: 5px;
				position: relative;
				top: 2px;
			}
			.order_select{
				overflow: hidden;
				.lineBtn{
					float: right;
				}
			}
			.order_select + span{
				position: absolute;
				top: 46px;
				left: 0;
				display: block;
				width: 230px;
				background: $c_hov;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				z-index: 2;
				font-size: 12px;
				&:after{
					display: block;
					content: "";
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-top: 10px solid $c_hov;
					position: absolute;
					top: -17px;
					left: 20px;
					margin-left: -7px;
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
	}

	.total_value{
		border-top:1px solid #cccccc;
		padding: 5px;
		font-size: 16px;
		display: table;
		width: 100%;
		.value{
			font-size: 20px;
			display: table-cell;
			text-align: right;
		}
	}

	.totalFeeWrap{
		margin: 0 10px;
		position: relative;
		.col1,.col2,.col3{
			display: table;
			width: 100%;
			border-bottom: 1px solid #cccccc;
			margin-bottom: 10px;
			.value{
				display: table-cell;
				text-align: right;
				font-size: 20px;
			}
		}
		.col3{
			color: $c_hov;
			border-bottom: 1px solid $c_hov;
			.value{
				font-size: 30px;
			}
		}

		.col3 + span{
			position: absolute;
			letter-spacing: normal;
			bottom: -56px;
			right: 0;
			display: block;
			background: $c_hov;
			color: #fff;
			text-align: center;
			padding: 5px;
			z-index: 2;
			font-size: 12px;
			&:after{
				display: block;
				content: "";
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-top: 10px solid $c_hov;
				position: absolute;
				top: -17px;
				left: 68%;
				margin-left: -7px;
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}

	.searchTopTxt{
		margin: 30px 10px 10px;
		font-size: 18px;
		text-align: center;
		font-family: $ff_min;
		color: #333;
	}
}//sp

.totalFeeWrap{
	@if $type == pc{
		float: right;
		width: 582px;
		margin-top: 20px;
		color: #333333;
	}

	.totalFee_head{
		@if $type == pc{
			background-color: #f6f6f6;
			letter-spacing: -.40em;
			margin-bottom: 10px;
		}
		.col1{
			@if $type == pc{
				text-align: center;
				display: inline-block;
				letter-spacing: normal;
				padding: 15px 35px 15px 5px;
				width: 200px;
				background: url(../images/second/img_plus.png) no-repeat 100% 50%;
			}
		}
		.col2{
			@if $type == pc{
				text-align: center;
				display: inline-block;
				letter-spacing: normal;
				padding: 15px 35px 15px 5px;
				width: 200px;
				background: url(../images/second/img_equal.png) no-repeat 100% 50%;
			}
		}
		.col3{
			@if $type == pc{
				text-align: center;
				display: inline-block;
				letter-spacing: normal;
				padding: 15px;
				width: 182px;
				color: #ab1f24;
			}
		}
	}

	.totalFee_value{
		@if $type == pc{
			border:1px solid #cccccc;
			box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
			letter-spacing: -.40em;
			position: relative;
		}
		.col1{
			@if $type == pc{
				text-align: center;
				display: inline-block;
				letter-spacing: normal;
				padding: 15px 35px 15px 5px;
				width: 200px;
				font-size: 20px;
			}
		}
		.col2{
			@if $type == pc{
				text-align: center;
				display: inline-block;
				letter-spacing: normal;
				padding: 15px 35px 15px 5px;
				width: 200px;
				font-size: 20px;
			}
		}
		.col3{
			@if $type == pc{
				text-align: center;
				display: inline-block;
				letter-spacing: normal;
				padding: 15px;
				width: 180px;
				color: #ab1f24;
				font-size: 30px;
			}
		}

		.col3 + span{
			@if $type == pc{
				position: absolute;
				letter-spacing: normal;
				top: 84px;
				right: 0;
				display: block;
				width: 300px;
				background: $c_hov;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				z-index: 2;
				font-size: 12px;
				&:after{
					display: block;
					content: "";
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-top: 10px solid $c_hov;
					position: absolute;
					top: -17px;
					left: 68%;
					margin-left: -7px;
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
	}

}//totalFeeWrap

//D-2,SD-2,D-3,SD-3
.tabMenuL{
		@if $type == pc{
			text-align: center;
			display: flex;
			justify-content: center;
			position: relative;
			margin-bottom: 50px;
		}
		@if $type == sp{
			border-bottom: 1px solid #cccccc;
			font-size: 0;
		}
		&:after{
			content: "";
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 1px;
			background: #ccc;
		}

		li{
			@if $type == pc{
				flex-basis: 353px;
				width: 353px;
				height: 44px;
				line-height: 44px;
				font-size: 12px;
				margin-right: 5px;
				text-align: center;
				&:last-child{
					margin-right: 0;
				}
			}
			@if $type == sp{
				width: 40%;
				display: inline-block;
				margin: 0 2% 0 9%;
				font-size: 12px;
				&:nth-child(2){
					margin: 0 9% 0 0;
				}
			}
		}

		li span{
			cursor: pointer;
			display: block;
			background: #efefef;
			border-bottom: none;
			color: $c_href;
			@if $type == pc{
				height: 44px;
				border-radius: 3px 3px 0 0;
			}
			@if $type == sp{
				height: 40px;
				padding: 10px 20px 0;
				border-radius: 3px 3px 0 0;
				text-align: center;
				border: 1px solid #efefef;
			}

			a{
				display: block;
				&:hover{
					text-decoration: none;
					color: $c_hov;
				}
			}
		}

	li.active span{
		background: #fff;
		border: 1px solid #ccc;
		border-bottom: none;
		color: $c_hov;
		//z-index: 10;
		position: relative;
		a{
			@if $type == pc{
				color: $c_hov;
			}
			@if $type == sp{
				color: $c_hov;
			}
		}

		&:after{
			content:"";
			width: 100%;
			height: 3px;
			background: $c_hov;
			bottom: 0;
			left: 0;
			position: absolute;
		}
	}

	&.tabMenuL_s{
		@if $type == sp{
			overflow-x: auto;
			white-space: nowrap;
			width: 100%;
			margin-bottom: 20px;
		}
		li{
			@if $type == pc{
				flex-basis: auto;
				width: auto;
				height: 36px;
				line-height: 36px;
				span{
					padding: 0 12px;
					height: 36px;
				}
			}
			@if $type == sp{
				margin: 0;
				width: 80px;
				margin-right: 5px;
			}
		}
	}
}//tabMenuL

@if $type == pc{
	.searchCheckWrap{
		.searchCheck{
			margin: -35px 0 0 0;
			padding: 0;
			overflow: hidden;
			.btnWrap{
				@if $type == pc{
					padding-bottom: 0;
				}
				@if $type == sp{
				}
			}
		}
		.searcCheckTitle{
			float: right;
			margin-bottom: 0;
		}
		.d1ReSearch{
			clear: both;
			padding-top: 25px;
		}
	}

	.itemBoxWrap{
		overflow: hidden;
		&:last-of-type .itemBox{
			margin-bottom: 30px;
		}
	}
	.itemBox{
		width: 290px;
		float: left;
		margin-right: 60px;
		margin-bottom: 60px;
		&:nth-child(3n){
			margin-right: 0;
		}
		.itemImg{
			margin-bottom: 13px;
		}
		.itemName{
			font-size: 16px;
			color: #333;
			float: left;
			margin-bottom: 8px;
			width: 230px;
			cursor: pointer;
			&:hover{
				@if $type == pc{
					color: $c_hov;
				}
			}
		}
		.itemType{
			float: right;
			text-align: center;
			font-size: 12px;
			padding: 0 5px;
			color: $c_href;
			border-radius: 3px;
			margin: 0;
			border: 1px solid $c_href;
		}
		.shopName{
			font-size: 12px;
			color: #a39679;
			clear: both;
			margin-bottom: 3px;
			display: block;
			clear: both;
			&:hover{
				color: $c_hov;
			}
		}
		.itemPrice{
			font-size: 18px;
			margin-bottom: 5px;
		}
		.itemExTxt{
			font-size: 12px;
			color: #666;
			margin-bottom: 10px;
		}
		.itemInner{
			display: table;
			width: 100%;
		}
		.itemNumber{
			width: 120px;
			display: table-cell;
			.spinnerWrap{
				margin-left: 5px;
				display: inline-block;
			}
			.spinner,.ui-spinner{
				width: 70px;
			}
		}
		.commonBtn{
			width: 124px;
			height: 34px;
			line-height: 34px;
			display: table-cell;
		}
		.itemHeart{
			display: table-cell;
			height: 30px;
			width: 46px;
			border:none;
			margin-left: 0;
			vertical-align: middle;
			background: url(../images/second/heart.png) no-repeat center center;
			cursor: pointer;
			&:hover{
				background: url(../images/second/heart_hover.png) no-repeat center center;
			}
		}
	}//itemBox

	.shopBoxWrap{
		.itemBox{
			.shopText{
				font-size: 12px;
				clear: both;
				margin-bottom: 10px;
			}
			.commonBtn{
				display: inline-block;
				width: 188px;
				padding: 0;
				margin-left: 51px;
			}
		}
	}//shopBoxWrap

	.rankingArea{
		background: url(../images/second/rankingarea_bg.png) repeat 0 0;
		padding: 11px 0 2px;
		margin-bottom: 50px;
		.rankingAreaIn{
			background: url(../images/common/f_bg.png) repeat center top;
			padding: 39px 30px 48px;
		}
		h2{
			color: #333;
			font-family: $ff_min;
			font-size: 20px;
			display: inline-block;
			border-bottom: 1px solid #999;
			padding-bottom: 2px;
			margin-bottom: 40px;
		}
		.itemBox{
			margin-right: 30px;
			margin-bottom: 0;
			position: relative;
			&:nth-child(1):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank1.png) repeat 0 0;
				width: 62px;
				height: 62px;
				top: -11px;
				left: -6px;
				z-index: 1;
			}
			&:nth-child(2):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank2.png) repeat 0 0;
				width: 62px;
				height: 62px;
				top: -11px;
				left: -6px;
				z-index: 1;
			}
			&:nth-child(3):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank3.png) repeat 0 0;
				width: 62px;
				height: 62px;
				top: -11px;
				left: -6px;
				z-index: 1;
			}
			&:nth-child(3n){
				margin-right: 0;
			}
			.itemName{
				color: #b32e38;
				&:hover{
					@if $type == pc{
						opacity: 0.8;
					}
				}
			}
			.itemPrice{
				clear: both;
				color: #333333;
			}
		}
	}

	.bottomShowInfo{
		width: 990px;
		margin: 0 auto 30px;
		text-align: center;
		padding-top: 20px;
		position: relative;
		&.delBtIn{
			.delBtn{
				position: absolute;
				right: 0;
				top: 50%;
			}
		}
	}//bottomShowInfo

	.pagerText{
		font-size: 12px;
		color: #333;
		margin-bottom: 10px;
	}
	.pager{
		text-align: center;
		font-size: 16px;
		color: #333;
		.pagerLinkPrev{
			color: #333;
			position: relative;
			padding-left: 12px;
			margin-right: 15px;
			&:hover{
				color: $c_hov;
			}
			&:after{
				display: block;
				position: absolute;
				width: 10px;
				height: 10px;
				border-top: solid 1px #333;
				border-right: solid 1px #333;
				-webkit-transform: rotate(225deg);
				transform: rotate(225deg);
				content: "";
				top: 50%;
				margin-top: -5px;
				left: 0px;
			}
		}
		.pagerLink{
			color: #333;
			display: inline-block;
			height: 28px;
			line-height: 28px;
			text-align: center;
			text-decoration: none;
			width: 28px;
			&:hover{
				@if $type == pc{
					color: $c_hov;
				}
			}
		}
		.pagerStay{
			color: #848484;
			display: inline-block;
			height: 28px;
			line-height: 28px;
			text-align: center;
			text-decoration: none;
			width: 28px;
			background-color: #f5f2ea;
		}
		.pagerLinkNext{
			color: #333;
			position: relative;
			padding-right: 12px;
			margin-left: 15px;
			&:hover{
				color: $c_hov;
			}
			&:after{
				display: block;
				position: absolute;
				width: 10px;
				height: 10px;
				border-top: solid 1px #333;
				border-right: solid 1px #333;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				content: "";
				top: 50%;
				margin-top: -5px;
				right: 0px;
			}
		}
	}//pager

	.topShowInfo{
		overflow: hidden;
		margin-bottom: 25px;
		.pagerText{
			margin-top: 5px;
			margin-bottom: 0;
			display: inline-block;
		}
		.pager{
			margin-top: 5px;
			display: inline-block;
			.pagerLinkNext{
				font-size: 12px;
				&:after{
					width: 8px;
					height: 8px;
					margin-top: -5px;
				}
			}
		}
		.topShowInfoIn{
			float: right;
		}
		.sortMenu{
			display: inline-block;
			button{
				display: inline-block;
				cursor: pointer;
				padding: 4px 25px 4px 10px;
				margin-right: 5px;
				border: 1px solid #f4f4f4;
				background-color: #f4f4f4;
				color: #848484;
				font-size: 12px;
				border-radius: 6px;
				position: relative;
				&:after{
					display: block;
					position: absolute;
					width: 7px;
					height: 7px;
					border-top: solid 1px #333;
					border-right: solid 1px #333;
					-webkit-transform: rotate(135deg);
					transform: rotate(135deg);
					content: "";
					top: 50%;
					margin-top: -5px;
					right: 8px;
				}
				&.active,
				&:hover{
					border: 1px solid $c_hov;
					background-color: #fff;
					color: #b3424a;
					span{
						opacity: 0.6 !important;
					}
					&:after{
						border-top: solid 1px $c_hov;
						border-right: solid 1px $c_hov;
					}
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
		.showNumber{
			display: inline-block;
			margin-left: 25px;
		}
	}
	.ico_heart_k{
		display: inline-block;
		position: relative;
		width: 12px;
		height: 11px;
		background: url(../images/common/ico_heart_k.png) no-repeat center center;
		vertical-align: middle;
		margin-right: 4px;
	}
	&.active .ico_heart_k,
	button:hover .ico_heart_k{
		background: url(../images/common/ico_heart_k_on.png) no-repeat center center;
	}
	.ico_clock_k{
		display: inline-block;
		position: relative;
		width: 11px;
		height: 11px;
		background: url(../images/common/ico_clock_k.png) no-repeat center center;
		vertical-align: middle;
		margin-right: 4px;
	}
	&.active .ico_clock_k,
	button:hover .ico_clock_k{
		background: url(../images/common/ico_clock_k_on.png) no-repeat center center;
	}
	.ico_money_k{
		display: inline-block;
		position: relative;
		width: 9px;
		height: 11px;
		background: url(../images/common/ico_money_k.png) no-repeat center center;
		vertical-align: middle;
		margin-right: 4px;
	}
	&.active .ico_money_k,
	button:hover .ico_money_k{
		background: url(../images/common/ico_money_k_on.png) no-repeat center center;
	}

	.bgGrayImg{
		background: url(../images/common/f_bg.png) repeat center top;
	}
	.relatedLinks{
		margin-bottom: 35px;
		h2{
			color: #333;
			font-family: $ff_min;
			font-size: 16px;
			display: inline-block;
			border-bottom: 1px solid #999;
			padding-bottom: 2px;
			margin-bottom: 20px;
		}
		.relatedLinksIn{
			background-color: #fff;
			border-radius: 6px;
			padding: 15px;
			a{
				display: inline-block;
				font-size: 12px;
				margin-right: 23px;
			}
		}
	}
}//pc

@if $type == sp{
	.itemBoxWrap{
		margin: 0 10px;
		&.tabBodyWrap{
			margin: 0;
		}
	}
	.itemBox{
		width: 100%;
		margin-bottom: 25px;
		padding-bottom: 25px;
		border-bottom: 1px solid #ccc;
		.itemImg{
			text-align: center;
			margin-bottom: 13px;
		}
		.itemName{
			font-size: 18px;
			color: #333;
			float: left;
			margin-bottom: 8px;
			width: 80%;
		}
		.itemType{
			float: right;
			text-align: center;
			font-size: 13px;
			padding: 0 5px;
			color: $c_href;
			margin: 0;
			border: 1px solid $c_href;
		}
		.shopName{
			font-size: 13px;
			color: #a39679;
			clear: both;
			margin-bottom: 3px;
			display: block;
			clear: both;
		}
		.itemText{
			font-size: 14px;
			margin-bottom: 10px;
			color: #848484;
		}
		.itemExTxt{
			clear: both;
			font-size: 12px;
			color: #666;
			margin-bottom: 10px;
		}
		.itemInner1{
			overflow: hidden;
			margin-bottom: 10px;
		}
		.itemPrice{
			font-size: 18px;
			float: left;
		}
		.itemNumber{
			width: 112px;
			float: right;
			.spinnerWrap{
				margin-left: 5px;
				display: inline-block;
			}
			.spinner,.ui-spinner{
				width: 70px;
			}
		}
		.itemInner2{
			overflow: hidden;
			text-align: center;
		}
		.commonBtn{
			width: 70%;
			margin-left: 35px;
			height: 55px;
			line-height: 55px;
		}
		.itemHeart{
			display: inline-block;
			float: right;
			width: 30px;
			height: 55px;
			border:none;
			background: url(../images/second/heart.png) no-repeat center center;
			cursor: pointer;
		}
	}//itemBox

   .shopBoxWrap{
		.itemBox{
			.shopText{
				font-size: 13px;
				clear: both;
				margin-bottom: 10px;
			}
			.commonBtn{
				display: inline-block;
				padding: 0;
			}
		}
	}//shopBoxWrap

	.topShowInfo{
		margin: 25px 10px;
		overflow: hidden;
		.pagerText{
			font-size: 10px;
			float: left;
			margin-top: 12px;
		}
		.topShowInfoIn{
			float: right;
		}
		.sortMenu{
			select{
				width: 160px;
			}
		}
	}//topShowInfo

	.bottomShowInfo{
		box-shadow: 0px 5px 5px 0px rgba(204,204,204,0.8);
		margin-bottom: 25px;
		padding: 0 10px 20px;
		.pagerText{
			font-size: 10px;
			margin-bottom: 15px;
		}
	}//bottomShowInfo

	.moreBtn{
		display: block;
		color: #816d3f;
		text-align: center;
		width: 100%;
		font-size: 16px;
		padding: 10px 0;
		background-color: #f5f2ea;
		position: relative;

		&:after{
			content: "";
			display: block;
			width: 12px;
			height: 12px;
			transform: rotate(45deg);
			border-right: 1px solid #816d3f;
			border-bottom: 1px solid #816d3f;
			position: absolute;
			top: 13px;
			right: 15px;
		}
	}//moreBtn

	.relatedLinks{
		dl{
			margin: 10px;
			dt{
				background:#f5f2ea;
				font-family: $ff_min;
				font-size: 13px;
				color: #816d3f;
				font-weight: normal;
				text-align: center;
				padding: 10px 0;
				position: relative;

				&:after{
					display: block;
					position: absolute;
					width: 12px;
					height: 12px;
					border-top: solid 1px #816d3f;
					border-left: solid 1px #816d3f;
					-webkit-transform: rotate(-135deg);
					transform: rotate(-135deg);
					content: "";
					top: 50%;
					margin-top: -8px;
					right: 15px;
				}

				&.open{
					background:#a39678;
					color: #fff;
					font-weight: normal;
					text-align: center;
					padding: 10px 0;
					position: relative;

					&:after{
						display: block;
						position: absolute;
						width: 12px;
						height: 12px;
						border-top: solid 1px #fff;
						border-left: solid 1px #fff;
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						content: "";
						top: 50%;
						margin-top: -3px;
						right: 15px;
					}
				}//&.open
			}//dt
			dd{
				font-size: 12px;
				border: 1px solid #ccc;
				border-top: none;
				border-radius: 0 0 3px 3px;
				padding: 15px;
				display: none;
				a{
					font-size: 9px;
					display: inline-block;
					margin: 0 10px 10px 0;
				}
			}//dd
		}
	}//relatedLinks

	.rankingArea{
		margin-top: 20px;
		background-image: url(../images/second/sp-d4-rank-bg.jpg);
		background-repeat: repeat-y;
		background-size: 25px auto;
		background-position: 0 0;
		background-color: #f5f5f5;
		padding: 0;
		&.tabBody{
			padding: 0;
		}
		.rankingAreaIn{
		}
		h2{
			color: #333;
			font-family: $ff_min;
			font-size: 16px;
			margin: 0 0 15px 35px;
			padding-top: 15px;
		}
		.itemBoxWrap{
			overflow-x: auto;
			white-space:nowrap;
			width: 100%;
			margin: 0;
		}
		&.osusume .itemBox:before{
			 display: none!important;
		}
		.itemBox{
			width: 220px;
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			margin-bottom: 0;
			padding-bottom: 25px;
			border: none;
			position: relative;
			&:nth-child(1){
				margin-left: 10px;
			}
			&:nth-child(1):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank1.png) no-repeat 0 0;
				background-size: 45px;
				width: 45px;
				height: 45px;
				top: -8px;
				left: -6px;
			}
			&:nth-child(2):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank2.png) no-repeat 0 0;
				background-size: 45px;
				width: 45px;
				height: 45px;
				top: -8px;
				left: -6px;
			}
			&:nth-child(3):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank3.png) no-repeat 0 0;
				background-size: 45px;
				width: 45px;
				height: 45px;
				top: -8px;
				left: -6px;
			}
			&:nth-child(4):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank4.png) no-repeat 0 0;
				background-size: 45px;
				width: 45px;
				height: 45px;
				top: -8px;
				left: -6px;
			}
			&:nth-child(5):before{
				position: absolute;
				content: "";
				display: block;
				background: url(../images/second/rank5.png) no-repeat 0 0;
				background-size: 45px;
				width: 45px;
				height: 45px;
				top: -8px;
				left: -6px;
			}
			&:nth-child(3n){
				margin-right: 0;
			}
			.itemName{
				font-size: 14px;
				margin-left: 25px;
				width: 140px;
				color: #b32e38;
				white-space:normal;
			}
			.itemPrice{
				float: none;
				white-space:normal;
				clear: both;
				color: #333333;
				font-size: 12px;
				margin-left: 25px;
			}
			.itemNumber{
				margin-bottom: 10px;
			}
			.itemInner2{
				clear: both;
				.commonBtn{
					margin-left: 25px;
					width: 120px;
					float: left;
				}
			}
		}
	}
}//sp

.itemHeart{
	@if $type == pc{
		display: inline-block;
		height: 30px;
		width: 30px;
		border:none;
		margin-left: 0;
		vertical-align: middle;
		background: url(../images/second/heart.png) no-repeat center center;
		cursor: pointer;
	}
	@if $type == sp{
		display: inline-block;
		width: 30px;
		height: 55px;
		border:none;
		background: url(../images/second/heart.png) no-repeat center center;
		cursor: pointer;
	}
	&.on,
	&.on:hover{
		background: url(../images/second/heart_add.png) no-repeat center center;
	}
	&:hover{
		@if $type == pc{
			background: url(../images/second/heart_hover.png) no-repeat center center;
		}
	}
	&.txt_itemHeart{
		@if $type == pc{
			display: inline-block;
			height: auto;
			width: auto;
			padding-left: 30px;
			background: url(../images/second/heart.png) no-repeat left center;
		}
		@if $type == sp{
			display: inline-block;
			height: auto;
			width: auto;
			padding-left: 30px;
			background: url(../images/second/heart.png) no-repeat left center;
		}
		&.on{
			@if $type == pc{
				color:$c_href ;
				background: url(../images/second/heart_add.png) no-repeat left center;
			}
			@if $type == sp{
				color:$c_href ;
				background: url(../images/second/heart_add.png) no-repeat left center;
			}
		}
	}
}

//D-9,SD-9,D-10,SD-10
@if $type == pc{
	.bgD9{
	   background: url(../images/second/bg_popularity.jpg) no-repeat 50% 50%;
	   background-size: cover;
		.topSearchArea{
			background: url(../images/second/popularity_txt.png) no-repeat  90% 155px;
		}
	}
	.bgD10{
	   background: url(../images/second/bg_feature.jpg) no-repeat 50% 50%;
	   background-size: cover;
		.topSearchArea{
			background: url(../images/second/feature_txt.png) no-repeat  90% 155px;
		}
	}
	.bgTopImg{
		min-height: 465px;
		.topSearchArea{
			width: 100%;
			min-height: 465px;
			margin: auto;
			padding-top: 110px;
		}
		.topSearchBox{
			margin-top: 240px;
			background-color: rgba(255,255,255,0.8);
			width: 643px;
			height: 96px;
			overflow: hidden;
			margin-left: 20px;
			dl{
				float: left;
				font-size: 13px;
				width: 235px;
				padding-right: 10px;
				margin: 18px 10px;
				dt{
					margin-bottom: 5px;
					color: #333;
				}
				select{
					width: 100%;
				}
			}
			.commonBtn{
				float: left;
				font-size: 14px;
				width: 116px;
				margin: 36px 0 0;
			}
		}
	}

	.productInfo2{
		padding: 0;
		margin: 0;
		position: relative;
		margin-bottom: 60px;
		.productSpec{
			.productName{
				margin-bottom: 10px;
				.tag{
					border-radius: 2px;
				}
				.productTitle{
					padding-top: 12px;
					padding-bottom: 5px;
					a{
						color: #333;
						&:hover{
							color: $c_hov;
						}
					}
				}
			}
			.aboutProduct{
				margin-bottom: 5px;
			}
			.productPrice{
				margin-bottom: 7px;
			}
			.order{
				margin-bottom: 25px;
				.spinner{
					height: 44px !important;
				}
			}
		}

		&:before{
			position: absolute;
			content: "";
			display: block;
			width: 62px;
			height: 62px;
			top: -11px;
			left: -6px;
		}
		&:nth-child(1):before{
			background: url(../images/second/rank1.png) repeat 0 0;
			width: 62px;
			height: 62px;
			top: -11px;
			left: -6px;
		}
		&:nth-child(2):before{
			background: url(../images/second/rank2.png) repeat 0 0;
		}
		&:nth-child(3):before{
			background: url(../images/second/rank3.png) repeat 0 0;
		}
		&:nth-child(4):before{
			background: url(../images/second/rank4.png) repeat 0 0;
		}
		&:nth-child(5):before{
			background: url(../images/second/rank5.png) repeat 0 0;
		}
		&:nth-child(6):before{
			background: url(../images/second/rank6.png) repeat 0 0;
		}
		&:nth-child(7):before{
			background: url(../images/second/rank7.png) repeat 0 0;
		}
		&:nth-child(8):before{
			background: url(../images/second/rank8.png) repeat 0 0;
		}
		&:nth-child(9):before{
			background: url(../images/second/rank9.png) repeat 0 0;
		}
		&:nth-child(10):before{
			background: url(../images/second/rank10.png) repeat 0 0;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	.productInfo{
		.productArea{
			dt{
				font-size: 12px;
				margin-bottom: 5px;
				color: #333;
			}
			dd{
				a{
					display: inline-block;
					background: #efefef;
					color: $c_href;
					font-size: 12px;
					padding: 10px 12px;
					border-radius: 3px;
				}
			}
		}
	}

	.productInfo3{
		padding: 0;
		margin: 0;
		margin-bottom: 60px;
		.productSpec{
			.productName{
				margin-bottom: 10px;
				.tag{
					border-radius: 2px;
				}
				.itemHeart{
					float: right;
					&:hover{
						background: url(../images/second/heart_hover.png) no-repeat center center;
					}
					&.on,
					&.on:hover{
						background: url(../images/second/heart_add.png) no-repeat center center;
					}
				}
				.productTitle{
					clear: both;
					padding-top: 0px;
					padding-bottom: 5px;
					a{
						color: #333;
						&:hover{
							color: $c_hov;
						}
					}
				}
			}
			.aboutProduct{
				margin-bottom: 40px;
			}
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}

@if $type == sp{
	.bgTopImg{
		text-align: center;
		position: relative;
		.bgTopImgTxt{
			position: absolute;
			bottom:0;
			background: rgba(#000,0.6);
			color: #fff;
			text-align: center;
			font-size: 12px;
			width: 100%;
			padding: 10px;
		}
	}
	.topSearchArea{
		margin-bottom: 20px;
	}
	.topSearchBox{
		-moz-box-pack: justify;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 10px;
		select{
			display: inline-block;
			flex-basis: 49%;
			font-size: 14px;
			margin-bottom: 5px;
			position: relative;
			width: 49%;
		}
		.commonBtn{
			margin: 10px auto;
		}
	}
	.productArea{
		margin-top: 25px;
		dt{
			font-size: 12px;
			margin-bottom: 5px;
			color: #333;
		}
		dd{
			font-size: 0;
			a{
				display: inline-block;
				letter-spacing: normal;
				background: #efefef;
				color: $c_href;
				font-size: 12px;
				padding: 15px 12px;
				border-radius: 3px;
				width: 24%;
				margin-right: 1%;
				margin-bottom: 5px;
				text-align: center;
				&:nth-child(4n){
					margin-right: 0;
				}
			}
		}
	}
	.itemBox2{
		.itemImg{
			position: relative;
			&:before{
				position: absolute;
				content: "";
				display: block;
				width: 62px;
				height: 62px;
				top: -11px;
				left: -6px;
			}
		}
		&:nth-child(1) .itemImg:before{
			background: url(../images/second/rank1.png) repeat 0 0;
			width: 62px;
			height: 62px;
			top: -11px;
			left: -6px;
		}
		&:nth-child(2) .itemImg:before{
			background: url(../images/second/rank2.png) repeat 0 0;
		}
		&:nth-child(3) .itemImg:before{
			background: url(../images/second/rank3.png) repeat 0 0;
		}
		&:nth-child(4) .itemImg:before{
			background: url(../images/second/rank4.png) repeat 0 0;
		}
		&:nth-child(5) .itemImg:before{
			background: url(../images/second/rank5.png) repeat 0 0;
		}
		&:nth-child(6) .itemImg:before{
			background: url(../images/second/rank6.png) repeat 0 0;
		}
		&:nth-child(7) .itemImg:before{
			background: url(../images/second/rank7.png) repeat 0 0;
		}
		&:nth-child(8) .itemImg:before{
			background: url(../images/second/rank8.png) repeat 0 0;
		}
		&:nth-child(9) .itemImg:before{
			background: url(../images/second/rank9.png) repeat 0 0;
		}
		&:nth-child(10) .itemImg:before{
			background: url(../images/second/rank10.png) repeat 0 0;
		}
		&:last-child{
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}


.rank4to10{
	.productInfo2:nth-child(1):before{
		background: url(../images/second/rank4.png) repeat 0 0;
	}
	.productInfo2:nth-child(2):before{
		background: url(../images/second/rank5.png) repeat 0 0;
	}
	.productInfo2:nth-child(3):before{
		background: url(../images/second/rank6.png) repeat 0 0;
	}
	.productInfo2:nth-child(4):before{
		background: url(../images/second/rank7.png) repeat 0 0;
	}
	.productInfo2:nth-child(5):before{
		background: url(../images/second/rank8.png) repeat 0 0;
	}
	.productInfo2:nth-child(6):before{
		background: url(../images/second/rank9.png) repeat 0 0;
	}
	.productInfo2:nth-child(7):before{
		background: url(../images/second/rank10.png) repeat 0 0;
	}
}
