
main{
	@if $type == pc{
		padding-top: 110px;
	}
	@if $type == sp{
	}
}

.simpleHead + main,
.loginHead + main{
	@if $type == pc{
		padding-top: 0;
	}
	@if $type == sp{
	}
}

footer{
	@if $type == pc{
		margin-top: 100px;
	}
	@if $type == sp{
		padding-top: 20px;
	}
}

.breadList{
	background:#f6f6f6;
	width: 100%;
	@if $type == pc{
		min-width: 1000px;
		margin-bottom: 50px;
	}

	@if $type == sp{
		padding: 5px 0;
		margin-bottom: 25px;
	}

	.breadListIn{
		width: 100%;
		@if $type == pc{
			min-width: 1000px;
			margin: 0 auto;
		}
		@if $type == sp{
			padding-left: 5px;
			overflow-x: scroll;
			white-space: nowrap;
		}
		padding-top: 3px;

		div{
			display: inline-block;
			padding: 13px 10px 13px 40px;
			position: relative;
			font-size: 13px;
			color: $c_red;
			@if $type == sp{
				font-size: 12px;
				padding: 0px 0px 5px 20px;
			}

			&:before{
				display: block;
				position: absolute;
				width: 15px;
				height: 15px;
				margin-top: -10px;
				top: 50%;
				left: 0px;
				border-top: solid 1px #cecece;
				border-right: solid 1px #cecece;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				content: "";
				@if $type == sp{
					width: 10px;
					height: 10px;
					margin-top: -9px;
					top: 50%;
					left: 0px;
				}
			}
			&:first-child{
				@if $type == pc{
					padding-left: 20px;
				}
				@if $type == sp{
					padding-left: 5px;
				}
				&:before{
				display: none;
				}
			}
		}
	}
}//.breadList

.bgWhite{
	background:#fff;
}

.bgGray{
	background:#f4f4f4;
}

.bgIn{
	@if $type == pc{
		width: $site_w;
		margin: 0 auto;
		padding: 35px 0;
	}
	@if $type == sp{
		padding: 20px 0;
	}
}

.breadList + .bgIn{
	@if $type == pc{
		padding-top: 0;
		padding-bottom: 25px;
	}
	@if $type == sp{
	}
}
form .bgIn:first-child{
	@if $type == pc{
		padding-top: 0;
		padding-bottom: 0;
	}
	@if $type == sp{
		padding-top: 0;
		padding-bottom: 0;
	}
}

.btnTopTxt{
	margin-bottom: 15px;
	padding-top: 10px;
	text-align: center;
	@if $type == sp{
		margin-bottom: 5px;
	}
}

.input_alert{
	border: 1px solid $c_hov !important;
}

.formTitleArea{
	@if $type == pc{
		margin-bottom: 25px;
	}
	@if $type == sp{
		margin-bottom: 20px;
	}
	h1{
		color: #333;
		font-family: $ff_min;
		font-size: 24px;
		display: inline-block;
		border-bottom: 1px solid #999;
		padding-bottom: 2px;
		@if $type == sp{
			display: block;
			font-family: $ff_min;
			font-size: 18px;
			margin: 40px 10px 20px;
			line-height: 1.5;
			padding-bottom: 10px;
		}
	}
}

.cartListHead{
	background:#f6f6f6;
	display: table;
	margin-bottom: 10px;

	.col{
		display: table-cell;
		padding: 15px;
		color: #333333;
	}
}

@if $type == pc{
	.cartListWrap{
		border:1px solid #cccccc;
		box-shadow: 0px 1px 3px rgba(0,0,0,0.2);

		.cartListOne{
			padding: 30px;
			border-top: 1px solid #cccccc;

			&:first-child{
				border-top: none;
			}
		}
		.col{
			float: left;
			vertical-align: top;

			&:first-child{
			}
		}
		.col_info{
			width: 380px;
			padding-right: 30px;
			.img{
				float: left;
				width: 120px;
			}
			.infoIn{
				float: right;
				width: 225px;
				padding-left: 5px;
				text-align: left;
				.price{
					margin-top: 10px;
				}
			}
			.shopLabel{
				clear: both;
				padding-top: 10px;
			}
		}
		.col_select_in{
			display: inline-block;
			width: 192px;
			padding: 0 4px;
			margin-bottom: 10px;
			dl{
				dt{
					font-size: 12px;
					padding-bottom: 3px;
				}
				dd{
				}
			}
			select{
				width: 100%;
				border: 1px solid #ccc;
				font-size: 1em;
			}
		}
		.col_total{
			width: 160px;
			padding-left: 15px;
			padding-top: 20px;
			position: relative;
			.ui-widget.ui-widget-content{
				float: right;
			}

			.order_select + span{
				position: absolute;
				top: 65px;
				right: 0;
				display: block;
				width: 230px;
				background: $c_hov;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				z-index: 2;
				font-size: 12px;
				&:after{
					display: block;
					content: "";
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-top: 10px solid $c_hov;
					position: absolute;
					top: -17px;
					left: 70%;
					margin-left: -7px;
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}

		}

		.total_value{
			float: right;
			text-align: right;
			padding-top: 21px;

			.value{
				font-size: 16px;
			}
			button{
				margin-left: 15px;
			}
		}
	}
}

/* Spinner
----------------------------------------*/

.spinnerWrap{
	input{
		width: 100px;
	}
	.ui-widget.ui-widget-content{
		border: 0px;
		border-radius: 0px;
		input[type="text"],
		input[type="number"]{
			height: 34px;
			border: 1px solid #ccc;
			padding: 4px 10px;
			margin: 0;
			border-radius: 3px;
			background: #FFF;
		}
		a.ui-spinner-button{
			background: #fff;
			border:none;
			border-left: 1px solid #ccc;
			margin: 1px;
			&+a.ui-spinner-button{
				border-top: 1px solid #ccc;
			}
		}
		.ui-button .ui-icon{
			background-color: #fff;
			border-radius: 0;
		}
	}
}

/* check box
----------------------------------------*/

.img_cb{
	font-size: 14px;
	float: left;
	margin-right: 35px;
	margin-bottom: 20px;
	position: relative;
	height: 20px;
	overflow: hidden;

	input[type="checkbox"]{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	label{
		display: block;
		padding-left: 27px;
		background:url("../images/common/checkbox.png") no-repeat 0 bottom;
		position: relative;
		z-index: 2;
		height: 20px;
	}
	input[type="checkbox"]:checked + label{
		background:url("../images/common/checkbox.png") no-repeat 0 0;
	}
}

.form_comp_body{
	padding-top: 20px;
	p.alignCenter img{
		@if $type == pc{
		}
		@if $type == sp{
			width: 230px;
		}
	}
}

.form_comp_bottom_txt{
	text-align: center;

	div{
		text-align: center;
		color: $c_hov;
		font-size: 16px;
	}
	p{
		width: 600px;
		margin: 0 auto 30px;
		font-size: 14px;
		border-top: 1px solid $c_hov;
		border-bottom: 1px solid $c_hov;
		text-align: center;
		padding: 20px 0;
		@if $type == sp{
			width: auto;
			margin: 0 10px;
		}
	}
	ul{
		width: 600px;
		margin: 0 auto 0px;
		font-size: 14px;
		border-top: 1px solid $c_hov;
		border-bottom: 1px solid $c_hov;
		padding: 20px 0;
		@if $type == sp{
			width: auto;
			margin: 0 10px;
		}
	}
}

input[type = text].datepickerinput{
		background-image: url(../images/common/select_bt_cal.png);
		background-position: right top;
		background-repeat: no-repeat;
		height: 34px;
		@if $type == sp{
			font-size: 14px;
			background-image: url(../images/sp/common/select_bt_cal.png);
			background-size: 40px;
		}
}

.home_tokushu div.tokushu_list{
	@if $type == pc{
		margin-bottom: 0;
	}
	@if $type == sp{
	}
}

.tabMenu,
.tabMenuB{
	@if $type == pc{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		flex-direction: row;
		margin-top: 15px;
		font-size: 0;
	}
	@if $type == sp{
		overflow-y:scroll;
		white-space: nowrap;
		border-bottom: 1px solid #cccccc;
		font-size: 0;
	}

	li{
		@if $type == pc{
			flex-basis: 64px;
			width: 64px;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
			margin-right: 5px;
			text-align: center;
		}
		@if $type == sp{
			display: inline-block;
			margin-right: 5px;
			font-size: 12px;
		}

		&:last-child{
			margin-right: 0;
		}
	}

	span{
			display: block;
			cursor: pointer;
			background: #efefef;
			border: 1px solid #ccc;
			border-bottom: none;
			border-radius: 3px 3px 0 0;
			color: $c_href;
		@if $type == pc{
			height: 35px;
		}
		@if $type == sp{
			height: 40px;
			padding: 10px 20px 0;
		}
	}

	li.active span{
		background: #fff;
		color: $c_hov;
		z-index: 10;
		position: relative;
		@if $type == sp{
			box-shadow: 0px 0px 5px 0px rgba(100,100,100,0.5);
		}

		&:after{
			content:"";
			width: 100%;
			height: 2px;
			background: $c_hov;
			bottom: 0;
			left: 0;
			position: absolute;
		}
	}
	@if $type == pc{
		li:hover span{
			background: #fff;
			color: $c_hov;
			z-index: 10;
			position: relative;
			@if $type == sp{
				box-shadow: 0px 0px 5px 0px rgba(100,100,100,0.5);
			}

			&:after{
				content:"";
				width: 100%;
				height: 2px;
				background: $c_hov;
				bottom: 0;
				left: 0;
				position: absolute;
			}
		}
	}
}


.tabBody{
	background: #fff;
	border-radius: 0 0 3px 3px;
	@if $type == pc{
	}
	@if $type == sp{
		padding: 15px 10px 20px;
	}
	&.content{
		display: none;
		&:nth-child(1) {
			display: block;
		}
	}
	.shopDetailTxt{
		@if $type == pc{
		}
		@if $type == sp{
			margin-left: 0;
			margin-right: 0;
		}
	}

	li{
		display: inline-block;
		letter-spacing: normal;
		@if $type == pc{
			margin: 0 10px 20px 0;
		}
		@if $type == sp{
			margin: 0 10px 10px 0;
		}
	}

	li a{
		display: block;
		padding: 10px 13px;
		background: #f5f2ea;
		color: #996633;
		border: 1px solid #f5f2ea;
		border-radius: 20px;

		&:hover{
			color: $c_hov;
			background: #fff;
			border: 1px solid $c_hov;
		}
	}
}

.tabBodyWrapB{
	.tabBody{
		&.content{
			display: none;
			&:nth-child(1) {
				display: none;
			}
		}
		&.show{
			display: block;
		}
	}
}

.anc_shift{
	@if $type == pc{
		position: relative;
		top: -20px;
		padding-top: 20px;
	}
	@if $type == sp{
		position: relative;
		top: -25px;
		padding-top: 25px;
	}
}

.itemImgTxtLink{
	@if $type == pc{
		display: block;
	}
	@if $type == sp{
		display: block;
	}
	&:hover{
		 img{
			@if $type == pc{
				opacity: 0.8;
			}
			@if $type == sp{
			}
		}
		span{
			@if $type == pc{
				opacity: 0.8;
				color: $c_hov;
			}
			@if $type == sp{
			}
		}
	}
}
.hov:hover{
	@if $type == pc{
		opacity: 0.8;
	}
	@if $type == sp{
	}
}

.r3head{
	@if $type == pc{
		position: relative;
	}
	@if $type == sp{
	}
	.user_info{
		@if $type == pc{
			width: 650px;
			position: absolute;
			top: 0;
			right:0;
			overflow: hidden;
			text-align: right;
		}
		@if $type == sp{
			margin: 0 10px;
			overflow: hidden;
		}
		.user_name{
			@if $type == pc{
				display: inline-block;
				font-size: 14px;
				margin-right: 20px;
			}
			@if $type == sp{
				font-size: 12px;
				float: left;
				padding-top: 6px;
			}
		}
		.user_point_txt{
			@if $type == pc{
				display: inline-block;
			}
			@if $type == sp{
				float: right;
				font-size: 12px;
			}
			.point{
				@if $type == pc{
					color: $c_red;
					font-size: 24px;
					margin: 0 15px;
				}
				@if $type == sp{
					color: $c_red;
					font-size: 18px;
					margin: 0 7px;
				}
			}
		}//.user_point_txt
	}//.user_info
}//.r3head

.shopFavListWrap{
	@if $type == pc{
	}
	@if $type == sp{
	}
	&.itemBoxWrap {
		@if $type == pc{
		}
		@if $type == sp{
			margin: 0;
		}
		& > .itemBox{
			@if $type == pc{
			}
			@if $type == sp{
				border-bottom: none;
				padding-bottom: 0;
			}
			&:after{
				@if $type == pc{
				}
				@if $type == sp{
					content: "";
					display: block;
					margin: 20px 20px 0;
					border-bottom: 1px solid #ccc;
				}
			}
		}
	}
	.productInfo{
		.productImg{
			@if $type == pc{
				width: 380px;
			}
			@if $type == sp{
			}
			img{
				@if $type == pc{
					width: 100%;
				}
				@if $type == sp{
				}
			}
		}
		.productSpec{
			@if $type == pc{
				width: 580px;
			}
			@if $type == sp{
			}
			.aboutProduct{
				@if $type == pc{
					margin-bottom: 20px;
				}
				@if $type == sp{
				}
			}
			.best5{
				h2{
					@if $type == pc{
						margin-top: 0;
						font-size: 14px;
						padding-bottom: 7px;
						margin-bottom: 15px;
					}
					@if $type == sp{
					}
				}
			}
		}
	}
	.itemTxtFav{
		@if $type == pc{
		}
		@if $type == sp{
			clear: both;
			overflow: hidden;
		}
		.shopText{
			@if $type == pc{
			}
			@if $type == sp{
				float:left;
				width : 90% ; /* IE8以下とAndroid4.3以下用フォールバック */
				width : -webkit-calc(100% - 40px) ;
				width : calc(100% - 40px) ;
			}
		}
		.itemHeart{
			@if $type == pc{
			}
			@if $type == sp{
				float:right;
				height: 30px;
			}
		}
	}
	.rankingArea{
		@if $type == pc{
		}
		@if $type == sp{
			background-image: none;
		}
		.rankingAreaIn{
			h2{
				@if $type == pc{
				}
				@if $type == sp{
					margin-left: 10px;
				}
			}
			.itemName{
				@if $type == pc{
				}
				@if $type == sp{
					margin-bottom: 0;
				}
			}
		}
	}
}//shopFavListWrap

.img_secondHeader{
	@if $type == pc{
		min-width: 1200px;
	}
	@if $type == sp{
	}
	img{
		@if $type == pc{
			width: 100%;
		}
		@if $type == sp{
		}
	}
}

.rankTop3InWrap{
	&.itemBoxWrap{
		@if $type == pc{
		}
		@if $type == sp{
			margin-left: 0;
			margin-right: 0;
		}
	}
	.rankH,
	.rankHs{
		color: $c_href;
		font-family: $ff_min;
		text-align: center;
		margin-bottom: 40px;
		@if $type == pc{
			padding-top: 30px;
			font-size: 18px;
			background: url("../images/second/ico_rank.png") no-repeat center top;
		}
		@if $type == sp{
			padding-top: 25px;
			font-size: 16px;
			background: url("../images/sp/second/ico_rank.png") no-repeat center top;
			background-size: 30px;
			margin-bottom: 20px;
		}
		span{
			@if $type == pc{
				display: inline-block;
				content: "";
				border-bottom: 1px solid $c_href;
				padding-bottom: 3px;
			}
		}
	}
	.rankHs{
		color: #333333;
		background: url("../images/second/ico_rank_s.png") no-repeat center top;
		@if $type == sp{
			background: url("../images/sp/second/ico_rank_s.png") no-repeat center top;
			background-size: 30px;
		}
		span{
			@if $type == pc{
				border-bottom: 1px solid #999999;
			}
		}
	}
	& > .rankTop3Wrap{
		@if $type == pc{
			background: url(../images/common/f_bg.png) repeat 0 0;
			position: relative;
			padding: 50px 30px;
			margin-bottom: 50px;
		}
		@if $type == sp{
			background: url(../images/common/f_bg.png) repeat 0 0;
			position: relative;
			padding: 30px 10px;
			margin-bottom: 30px;
		}
		&:before{
			content: "";
			display: block;
			width: 100%;
			height: 11px;
			background: url("../images/second/bg_rank_obi.png") no-repeat;
			position: absolute;
			top: 0;
			left: 0;
		}
		&:after{
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background: url("../images/second/bg_rank_obi.png") no-repeat;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.productInfo2{
			.productSpec {
				@if $type == pc{
					width: 420px;
				}
				.order .commonBtn{
					@if $type == pc{
						width: 200px;
					}
				}
			}
			&:nth-of-type(1):before{
				@if $type == pc{
					width: 100px;
					height: 100px;
					background: url(../images/second/rank1b.png) no-repeat 0 0;
					z-index: 2;
					top: -18px;
					left: -11px;
				}
			}
			&:nth-of-type(2):before{
				@if $type == pc{
					width: 100px;
					height: 100px;
					background: url(../images/second/rank2b.png) no-repeat 0 0;
					z-index: 2;
					top: -18px;
					left: -11px;
				}
			}
			&:nth-of-type(3):before{
				@if $type == pc{
					width: 100px;
					height: 100px;
					background: url(../images/second/rank3b.png) no-repeat 0 0;
					z-index: 2;
					top: -18px;
					left: -11px;
				}
			}
		}//.productInfo2
		.itemBox2{
			&:last-child{
				@if $type == sp{
					margin-bottom: 0;
					border-bottom: none;
				}
			}
			 .itemImg:before{
				@if $type == sp{
					width: 85px;
					height: 85px;
					top: -15px;
					left: -10px;
				}
			}
			&:nth-of-type(1) .itemImg:before{
				@if $type == sp{
					background: url(../images/second/rank1b.png) no-repeat 0 0;
					background-size: 85px;
				}
			}
			&:nth-of-type(2) .itemImg:before{
				@if $type == sp{
					background: url(../images/second/rank2b.png) no-repeat 0 0;
					background-size: 85px;
				}
			}
			&:nth-of-type(3) .itemImg:before{
				@if $type == sp{
					background: url(../images/second/rank3b.png) no-repeat 0 0;
					background-size: 85px;
				}
			}
		}//.itemBox2
	}//.itemBoxWrap
	& > .itemBox2{
		&:nth-of-type(1):before{
			@if $type == pc{
				background: url(../images/second/rank4.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(2):before{
			@if $type == pc{
				background: url(../images/second/rank5.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(3):before{
			@if $type == pc{
				background: url(../images/second/rank6.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(4):before{
			@if $type == pc{
				background: url(../images/second/rank7.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(5):before{
			@if $type == pc{
				background: url(../images/second/rank8.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(6):before{
			@if $type == pc{
				background: url(../images/second/rank9.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(7):before{
			@if $type == pc{
				background: url(../images/second/rank10.png) no-repeat 0 0;
				z-index: 2;
			}
		}
	}
	.itemBox2:last-child{
		@if $type == pc{
		}
		@if $type == sp{
			width: 100%;
			margin-bottom: 25px;
			padding-bottom: 25px;
			border-bottom: 1px solid #ccc;
		}
	}
}//rankTop3InWrap


.rankSilverWrap{
	@if $type == sp{
		margin: 0 10px;
	}
	.itemBox{
		@if $type == sp{
			position: relative;
		}
		&:before{
			@if $type == sp{
				position: absolute;
				content: "";
				display: block;
				width: 62px;
				height: 62px;
				top: -11px;
				left: -6px;
				z-index: 3;
			}
		}
		&:nth-of-type(1):before{
			@if $type == sp{
				background: url(../images/second/rank4.png) no-repeat 0 0;
			}
		}
		&:nth-of-type(2):before{
			@if $type == sp{
				background: url(../images/second/rank5.png) no-repeat 0 0;
			}
		}
		&:nth-of-type(3):before{
			@if $type == sp{
				background: url(../images/second/rank6.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(4):before{
			@if $type == sp{
				background: url(../images/second/rank7.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(5):before{
			@if $type == sp{
				background: url(../images/second/rank8.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(6):before{
			@if $type == sp{
				background: url(../images/second/rank9.png) no-repeat 0 0;
				z-index: 2;
			}
		}
		&:nth-of-type(7):before{
			@if $type == sp{
				background: url(../images/second/rank10.png) no-repeat 0 0;
				z-index: 2;
			}
		}
	}
}//.rankSilverWrap

.omakaseWrap{
	@if $type == sp{
		padding-top: 10px;
	}
	.itemBox .commonBtn{
		@if $type == pc{
			width: 100%;
			height: 44px;
			line-height: 34px;
			display: table-cell;
		}
		@if $type == sp{
		}
	}
	.itemBox{
		.itemNumber{
			@if $type == sp{
				width: auto;
			}
		}
		input[type=text].datepickerinput{
			@if $type == sp{
				background-size: 34px;
			}
		}
		select{
			@if $type == sp{
				height: 34px;
				background-size: 34px;
			}
		}
		.itemInner{
			@if $type == sp{
				overflow: hidden;
			}
		}
		.w48p{
			@if $type == sp{
				float: left;
				width: 45%;
			}
		}
		.w49p{
			@if $type == sp{
				float: right;
				width: 45%;
			}
		}
		.commonBtn{
			@if $type == sp{
				margin: 0 auto;
			}
		}
	}
}//.omakaseWrap

.omakaselead{
	@if $type == sp{
		padding: 0 30px;
		text-align: center;
	}
	img{
		@if $type == sp{
			width: 60%;
		}
	}
	p{
		@if $type == sp{
			padding-top: 10px;
			font-size: 12px;
			text-align: center;
			color: $c_href;
		}
	}
}

.omakaseTab.tabMenuL{
	li{
		@if $type == sp{
			width: 47%;
			margin: 0 1% 0 2%;
		}
		&:nth-child(2){
			@if $type == sp{
				margin: 0 2% 0 0;
			}
		}
		span{
			@if $type == sp{
				padding: 10px 0 0;
			}
		}
	}
}

.table{
	@if $type == pc{
		display: table;
		clear: both;
		width: 100%;
	}
	@if $type == sp{
		display: table;
		clear: both;
		width: 100%;
	}
	.cell{
		@if $type == pc{
			display: table-cell;
			vertical-align: top;
		}
		@if $type == sp{
			display: table-cell;
			vertical-align: top;
		}
		.itemHeart{
			@if $type == pc{
				width: 30px;
			}
			@if $type == sp{
				width: 40px;
				height: 30px;
			}
		}
	}
}

.rirekiNameFav{
	@if $type == pc{
		position: relative;
		top: -5px;
		margin-bottom: -5px;
	}
	@if $type == sp{
		position: relative;
		top: -5px;
		margin-bottom: -10px;
	}
}

.rirekiTop3Head{
	@if $type == pc{
	}
	@if $type == sp{
	}
	.tag{
		@if $type == pc{
			font-size: 12px;
			line-height: 1;
			color: #a39679;
			border: 1px solid #a39679;
			display: inline-block;
			padding: 3px 5px;
			border-radius: 3px;
		}
		@if $type == sp{
			font-size: 12px;
			line-height: 1;
			color: #a39679;
			border: 1px solid #a39679;
			padding: 3px 5px;
			border-radius: 3px;
			position: absolute;
			top: 10px;
			right: 0;
		}
	}
}

.shopAreaBtn{
	@if $type == pc{
	}
	@if $type == sp{
		padding: 20px 10px 10px;
	}
	p{
		@if $type == pc{
			margin-bottom: 15px;
		}
		@if $type == sp{
			margin-bottom: 15px;
		}
	}
	.shopAreaBtnIn{
		@if $type == pc{
		}
		@if $type == sp{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		button.commonBtn{
			@if $type == pc{
				width: 31%;
				float: left;
				margin: 0 2% 8px 0;
			}
			@if $type == sp{
				flex-basis: 49%;
				width: 49%;
				margin: 0 0 10px;
			}
		}
	}//.shopAreaBtnIn
}//.shopAreaBtn

.guideIn{
	@if $type == pc{
		width: 990px;
		margin: 0 auto;
		background:#fff;
	}
	@if $type == sp{
		padding: 10px;
	}
	.guideStep{
		@if $type == pc{
			display: table;
			width: 100%;
		}
		@if $type == sp{
			background:#fff;
		}
		h3{
			@if $type == pc{
			}
			@if $type == sp{
				text-align: center;
			}
		}
		.guideStepIn{
			@if $type == pc{
				display: table-cell;
				width: 33.3%;
				padding-left: 20px;
				padding-right: 20px;
				padding-bottom: 25px;
			}
			@if $type == sp{
				background:	#fff;
				padding: 10px;
			}
			&:nth-child(1){
				@if $type == pc{
				}
				@if $type == sp{
				}
			}
			&:nth-child(2){
				@if $type == pc{
				}
				@if $type == sp{
				}
			}
			&:nth-child(3){
				@if $type == pc{
				}
				@if $type == sp{
				}
			}
			.img1{
				@if $type == pc{
					padding-top: 25px;
					padding-bottom: 25px;
					text-align: center;
				}
				@if $type == sp{
					padding-top: 20px;
					padding-bottom: 20px;
					text-align: center;
				}
				img{
					@if $type == pc{
					}
					@if $type == sp{
						width: 200px;
					}
				}
			}
			p{
				@if $type == pc{
					min-height: 290px;
					font-size: 14px;
				}
				@if $type == sp{
					font-size: 16px;
				}
			}
			.img2{
				@if $type == pc{
					box-shadow: 0px 1px 5px rgba(0,0,0,0.5);
				}
				@if $type == sp{
					text-align: center;
					padding-top: 20px;
				}
				img{
					@if $type == pc{
					}
					@if $type == sp{
						box-shadow: 0px 1px 5px rgba(0,0,0,0.5);
					}
				}
			}
		}//.guideStepIn
	}//.guideStep
}//.guideIn

.aboutHead{
	@if $type == pc{
		position: relative;
	}
	@if $type == sp{
	}
	.aboutHeadIn{
		@if $type == pc{
			position: absolute;
			bottom:10px;
			right: 100px;
			width: 250px;
			height: 100px;
			background: rgba(#fff,0.75);
			padding: 15px;
		}
		@if $type == sp{
		}
		.txt{
			@if $type == pc{
				text-align: center;
				margin-bottom: 5px;
			}
			@if $type == sp{
			}
			img{
				@if $type == pc{
					width: 183px;
				}
				@if $type == sp{
				}
			}
		}
	}//aboutHeadIn
}//.aboutHead

.commonUl{
	@if $type == pc{
		padding-top: 7px;
	}
	@if $type == sp{
		padding-top: 10px;
	}
	li{
		position: relative;
		padding-left: 15px;
		margin-bottom: 10px;
		@if $type == pc{
			font-size: 14px;
		}
		@if $type == sp{
			font-size: 16px;
		}
		&:before{
			content: "";
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 2px;
			background: #333;
			position: absolute;
			top: 7px;
			left: 3px;
			@if $type == pc{
			}
			@if $type == sp{
				top: 9px;
			}
		}
	}
}//commonUl

.komeList{
	@if $type == pc{
		padding-top: 7px;
		padding-left: 10px;
		padding-right: 10px;
	}
	@if $type == sp{
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}
	li{
		text-indent: -1em;
		padding-left: 1em;
		text-align: left;
		margin-bottom: 5px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.ss2Txt1{
	@if $type == sp{
		width: 250px;
	}
}
.ss2Txt2{
	@if $type == sp{
		width: 250px;
	}
}

.kiyaliWrap{
	@if $type == pc{
		width: 990px;
		border:1px solid #aaa;
		margin-top: 30px;
		height: 500px;
		overflow-y: scroll;
		padding: 30px;
		color: #111;
	}
	@if $type == sp{
	}
	h2{
		@if $type == pc{
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px;
			border-bottom:1px solid #ccc;
			padding-bottom: 7px;
		}
		@if $type == sp{
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 20px;
			padding-bottom: 7px;
			padding-left: 15px;
		}
	}
	h3{
		@if $type == pc{
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 10px;
		}
		@if $type == sp{
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 10px;
			background:#f0f0f0;
			padding: 10px;
		}
	}
	p{
		@if $type == pc{
			margin-bottom: 25px;
		}
		@if $type == sp{
			margin: 0 10px 25px;
			font-size: 16px;
		}
	}
}//.kiyaliWrap
