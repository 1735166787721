$ff_mincho : YuMincho, "Yu Mincho", serif;

.formNavBar{
	@if $type == pc{
		margin-bottom: 30px;
	}
	@if $type == sp{
		margin-bottom: 20px;
	}
}

.titleH2{
	background-color: #f6f6f6;
	font-size: 16px;
	color: #333;
	min-height: 50px;
	margin-bottom: 10px;
	@if $type == pc{
		padding: 20px 30px 15px;
	}
	@if $type == sp{
		min-height: 0px;
		padding: 10px 10px 10px;
		font-weight: normal;
	}
}

.formBlock{
	width: $site_w;
	border: 1px solid #ccc;
	background-color: #fff;
	box-shadow: 0 2px 2px #ddd;
	margin-bottom: 30px;
	@if $type == pc{
		padding: 30px 0 10px;
	}
	@if $type == sp{
		padding: 10px;
		margin: 10px;
	}

	.dlWrap{
		margin-bottom: 20px;

		&.beige{
			@if $type == pc{
				background-color: #f5f2ea;
			}
			@if $type == sp{
				background-color: #f5f2ea;
				padding: 10px;
			}
			dl dd{
				@if $type == pc{
					color: #000;
				}
				@if $type == sp{
				}
			}
		}

		&.gray{
			margin: 0 auto 20px;
			background-color: #f6f6f6;
			padding-top: 20px;
			@if $type == pc{
				width: 950px;
			}
			@if $type == sp{
				width: auto;
				padding: 10px;
			}

			dl{
				dt{
					@if $type == pc{
						width: 348px;
					}
					@if $type == sp{
						width: auto;
						min-height: 25px;
					}
				}

				dd{
					vertical-align: bottom;
					color: #000;

					.bentoImg{
						width: 120px;
						display: inline-block;
						vertical-align: bottom;
						margin-right: 15px;
						@if $type == sp{
							width: 100%;
							text-align: center;
							margin-bottom: 10px;
						}

						img{
							@if $type == pc{
								width: 100%;
								height: auto;
							}
							@if $type == sp{
							}
						}
					}

					.bentCount{
						display: inline-block;
						vertical-align: bottom;

						.couontTitle{
							display: block;
						}

						.spinnerWrap{
							display: inline-block;
							width: 100px;
							margin-right: 10px;

							input{
								width: 100px;
							}
						}
					}
				}
			}
		}
		dl.disp{
			dt{
				@if $type == pc{
					padding-top: 0;
				}
				@if $type == sp{
					padding-top: 0;
					padding-left: 0;
				}
				&:after{
					@if $type == pc{
						display: none;
					}
					@if $type == sp{
						display: none;
					}
				}
			}
		}
	}//.dlWrap

	dl{
		font-size: 14px;
		width: 100%;
		@if $type == pc{
			display: table;
			padding-bottom: 20px;
		}
		@if $type == sp{
			padding-bottom: 10px;
		}

		&:last-child{
			@if $type == pc{
			}
			@if $type == sp{
				padding-bottom: 0;
			}
		}

		&.topDL{
			@if $type == pc{
				margin-top: -30px;
				padding-top: 20px;
			}
			@if $type == sp{
			}
		}

		&.capDL{
			dt{
				@if $type == pc{
					padding-top: 27px;
				}

				&:after{
					@if $type == pc{
						top: 26px;
					}
				}
			}
		}

		dt, dd{
			@if $type == pc{
				display: table-cell;
				vertical-align: top;
			}
			@if $type == sp{
			}
		}

		dt{
			padding-top: 8px;
			padding-right: 72px;
			position: relative;
			color: #333;
			@if $type == pc{
				width: 368px;
				text-align: right;
			}
			@if $type == sp{
				padding-left: 40px;
				margin-bottom: 10px;
			}

			&:after{
				display: block;
				content: "任意";
				width: 32px;
				height: 20px;
				font-size: 10px;
				line-height: 20px;
				text-align: center;
				background-color: #ccc;
				position: absolute;
				top: 7px;
				color: #fff;
				border-radius: 3px;
				@if $type == pc{
					right: 10px;
				}
				@if $type == sp{
					left: 0;
					border-radius: 2px;
				}
			}

			&.hissu{

				&:after{
					background-color: #ab1f24;
					content: "必須";
				}

				&.top50{

					&:after{
						@if $type == pc{
							top: 50px;
						}
						@if $type == sp{
							bottom: 0;
						}
					}
				}
			}
		}

		dd{

				:placeholder-shown {
				color: #ccc; }

				/* Google Chrome, Safari, Opera 15+, Android, iOS */
				::-webkit-input-placeholder {
				color: #ccc; }

				/* Firefox 18- */
				:-moz-placeholder {
				color: #ccc; opacity: 1; }

				/* Firefox 19+ */
				::-moz-placeholder {
				color: #ccc; opacity: 1; }

				/* IE 10+ */
				:-ms-input-placeholder {
				color: #ccc; }

				&:focus{
					background-color: #fdf0f1;
					border-color: #ab1f24;
				}

			.formInfo{
				color: #ab1f24;
				font-size: 12px;
				line-height: 1.3;
				@if $type == sp{
					display: block;
					padding-top: 5px;
				}
			}

			input[type = text]{
				height: 34px;
				border: 1px solid #ccc;
				border-radius: 3px;
				@if $type == pc{
					width: 417px;
				}
				@if $type == sp{
					height: 40px;
					width: 100%;
				}

				&.text203{
					@if $type == pc{
						width: 203px;
						margin-right: 10px;
					}
					@if $type == sp{
						width: 100%;
					}
				}

				&.pcode{
					width: 120px;
					margin-right: 5px;
				}

				&.datepicker,
				&.datepicker2{
					@if $type == pc{
						background-image: url(../images/common/select_bt_cal.png);
						background-position: right top;
						background-repeat: no-repeat;
					}
					@if $type == sp{
						background-image: url(../images/sp/common/select_bt_cal.png);
						background-size: 40px;
						background-position: right top;
						background-repeat: no-repeat;
					}
				}
			}

			select{
				&.select417{
					@if $type == pc{
						width: 417px;
					}
					@if $type == sp{
						width: 100%;
					}
				}

				&.select203{
					width: 203px;
					margin-right: 7px;
					@if $type == sp{
						width: 100%;
						margin-right: 0;
						margin-bottom: 10px;
					}
				}

				&.select120{
					width: 120px;
				}
			}
			.addressBtn{
				background-color: #99886b;
				color: #fff;
				border: none;
				position: relative;
				top: -2px;
				&:disabled{
					background: #ccc;
					cursor: default;
				}
			}

			.selectTitle{
				@if $type == pc{
					display: inline-block;
					margin-right: 10px;
				}
				@if $type == sp{
					display: block;
					margin-bottom: 10px;
				}

				&:last-child{
					margin-bottom: 0;
				}

				span{
					display: block;
					font-size: 13px;
				}
			}

			.boxTitle{

				span{
					display: block;
					font-size: 13px;
				}
			}

			.grayBg{
				display: inline-block;
				background-color: #f6f6f6;
				min-height: 40px;
				border-radius: 3px;
				margin-bottom: 15px;

				.radioBtn{
					display: inline-block;
					margin-right: 10px;
					min-height: 40px;
					line-height: 40px;
					position: relative;

					input[type = radio]{
						position: absolute;
						z-index: -1;
					}

					label{
						display: block;
						position: relative;
						padding-left: 30px;
						width: 100%;

						&:before{
							content: "";
							display: block;
							width: 20px;
							height: 20px;
							border-radius: 50%;
							border: 1px solid #bbb;
							position: absolute;
							top: 10px;
							left: 5px;
						}

						&.checked{

							&:before{
								border-color: #333;
								border-width: 5px;
							}
						}
					}
				}
			}

			.spinner100{
				width: 100px;

				input{
					width: 100px;
				}
			}

			textarea{
				height: 130px;
				border: 1px solid #ccc;
				border-radius: 3px;
				padding: 10px;
				@if $type == pc{
					width: 417px;
				}
				@if $type == sp{
					width: 100%;
				}
			}

			.memo{
				width: 417px;
				display: block;
				padding-top: 20px;
				@if $type == sp{
					width: auto;
				}

				&:first-child{
					padding-top: 0;
				}
			}
		}
	}

	&.checkBlock{
		dl{
			@if $type == sp{
				margin-bottom: 10px;
			}
			&:last-child{
				@if $type == sp{
					margin-bottom: 0;
					padding-bottom: 0;
				}
				dd{
					@if $type == sp{
						margin-bottom: 0;
					}
				}
			}
		}
		dt{
			padding-top: 0;
			@if $type == sp{
				padding-left: 0;
				margin-bottom: 3px;
			}

			&:after{
				display: none;
			}
		}

		dd{
			font-size: 16px;
			color: #333;

			span{
				font-size: 14px;
				color: #666;
			}
			.memo{
				font-size: 14px;
				color: #333;
			}
		}
	}
}

.btnArea{
	background: url(../images/common/f_bg.png) repeat center top;
	text-align: center;
	@if $type == pc{
		padding: 40px 0;
	}
	@if $type == sp{
		background:#fff;
		padding: 20px 0;
	}
	.commonBtn.gray{
		background-color: #666;

		&:hover{
			opacity: .7;
		}
	}
	&.bt2in{
		@if $type == sp{
			margin: 0px;
			padding: 10px ;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		button{
			@if $type == sp{
				flex-basis: 49%;
				width: 49%;
				margin: 0;
				font-size: 13px;
			}
		}
	}
}



/* ------------------ D-1 ----------------------- */

.secondHeader{
	@if $type == sp{
		position: relative;

		.bgImg{
			width: 100%;

			img{
				width: 100%;
				height: auto;
			}
		}

		.secondHeaderTitleArea{
			font-size: 18px;
			font-weight: 700;
			color: #333;
			font-family: serif;
			position: absolute;
			line-height: 1;
			left: 16px;
			top: 40%;
		}
	}
	@if $type == pc{

		&:after{
			content: "";
			display: block;
			clear: both;
			width: 1px;
			height: 0;
			overflow: hidden;
		}

		&.sagasu{
			width: 100%;
			height: 140px;
		}

		&.kanto{
			background-image: url(../images/second/top-img-kanto.png);
			background-repeat: no-repeat;
			color: #333;
			font-size: 32px;
			background-color: #fffaf5;
		}

		.secondHeaderTitleArea{
			font-family: $ff_mincho;
			height: 140px;
			float: left;

			h1{
				height: 140px;
				line-height: 140px;
				padding-left: 30px;
			}
		}

		.secondHeadFilter{
			float: right;
			padding-right: 20px;
			height: 140px;
			padding-top: 48px;

			select{
				width: 235px;
			}

			.commonBtn{
				&.filterBtn{
					width: 150px;
					height: 44px;
				}
			}
		}
	}
}

.searchCheck{
	@if $type == sp{
		padding-top: 0px;
		margin: 0 10px 15px;

		.searcCheckTitle{
			color: #816d3f;
			text-align: center;
			width: 100%;
			font-size: 13px;
			line-height: 50px;
			height: 50px;
			background-color: #f5f2ea;
			position: relative;
			margin-bottom: 20px;

			&:after{
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				transform: rotate(45deg);
				border-right: 2px solid #816d3f;
				border-bottom: 2px solid #816d3f;
				position: absolute;
				top: 13px;
				right: 20px;
			}

			&.opened{
				&:after{
					border-right: none;
					border-bottom: none;
					border-left: 2px solid #816d3f;
					border-top: 2px solid #816d3f;
					position: absolute;
					top: 21px;
				}
			}
		}

		.home_search{
			display: none;
		}

		.searchCheckList{
			display: flex;
			justify-content: space-between;

			a{
				display: block;
				background-color: #a39679;
				color: #fff;
				font-size: 12px;
				text-align: center;
				width: 32%;
				border-radius: 5px;
				padding: 13px 0 15px;
				text-decoration: none;

				span{
					font-size: 14px;
				}

				&:after{
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				transform: rotate(45deg);
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
					margin: 0 auto;
				}
			}
		}
	}
	@if $type == pc{
		padding: 0px 0 50px;
		text-align: center;

		.searcCheckTitle{
			color: #a39679;
			font-size: 14px;
			display: table;
			margin: 0 auto 50px;
			border-bottom: 1px solid #a39679;
			padding-bottom: 15px;
			cursor: pointer;
			position: relative;
			padding-left: 30px;

			&:before{
				content: "";
				width: 13px;
				height: 13px;
				border-right: 1px solid #a39679;
				border-bottom: 1px solid #a39679;
				transform: rotate(45deg);
				vertical-align: middle;
				display: block;
				position: absolute;
				left: 0;
				top: -1px;
			}

			&.opened{
				&:before{
					border-right: none;
					border-bottom: none;
					border-left: 1px solid #a39679;
					border-top: 1px solid #a39679;
					top: 7px;
				}
			}
			&:hover{
				@if $type == pc{
					color: $c_hov;
					border-color: $c_hov;
					&:before{
						border-color: $c_hov;
					}
				}//pc
			}
		}
	}
}

.d1ReSearch{
	@if $type == sp{
	}
	@if $type == pc{
		display: none;
		padding: 0;
	}
}

.uminH2{
	@if $type == sp{
		font-family: serif;
		display: table;
		font-size: 18px;
		line-height: 1;
		color: #333;
		border-bottom: 1px solid #999;
		padding: 0px 0 10px;
		margin-bottom: 20px;
		margin-top: 40px;
	}
	@if $type == pc{
		font-family: $ff_mincho;
		display: table;
		font-size: 20px;
		line-height: 1;
		color: #333;
		border-bottom: 1px solid #999;
		padding: 0px 0 13px;
		margin-bottom: 25px;
		margin-top: 80px;
	}
}

#genre{
	@if $type == sp{
		margin: 0 10px 15px;

		.genreList{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.genreItem{
				width: 49%;
				margin-bottom: 10px;

				a{
					display: block;
					position: relative;

					img{
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	@if $type == pc{
		.genreList{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.genreItem{
				width: 310px;
				height: 170px;
				margin-bottom: 30px;

				a{
					display: block;
					width: 310px;
					height: 170px;
					position: relative;
				}

				&.wide{
					width: 990px;
					height: 200px;

					a{
						width: 990px;
						height: 200px;
					}
				}
				@include hov_line;
			}
		}
	}
}

#kodawari{
	@if $type == sp{
		margin: 0 10px 15px;

		.buttons{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.commonBtn{
				width: 49%;
				font-size: 12px;
				display: block;
				margin: 0 0 10px;
				min-height: 55px;
				padding: 19px 25px 0;
				&.line2{
					padding-top: 10px;
				}
			}
		}
	}
	@if $type == pc{

		.buttons{
			display: flex;
			justify-content: space-between;

			.commonBtn{
				width: 225px;
				padding: 10px 25px;
				height: auto;
			}
		}
	}
}


#price{
	@if $type == sp{
		margin: 0 10px 15px;

		.buttons{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.commonBtn{
				width: 49%;
				font-size: 12px;
				display: block;
				margin: 0 0 10px;
				@if $type == pc{
					padding: 10px 25px;
					height: auto;
				}
				@if $type == sp{
					padding: 19px 25px 0;
					height: 55px;
				}
				&.line2{
					@if $type == sp{
						padding: 10px 10px 0 0;
						height: 55px;
					}
				}
			}
		}
	}
	@if $type == pc{
		margin-bottom: 10px;

		.buttons{
			display: flex;
			justify-content: space-between;

			.commonBtn{
				width: 140px;
			}
		}
	}
}




/* --------------------- D-4 --------------------- */

.spWrap{
	@if $type == sp{
		margin: 0 10px 15px;

		&.mb40{
			margin-bottom: 40px;
		}
	}
}

.d4Title{
	@if $type == sp{
		h1{
			color: #666;
			font-size: 12px;
		}

		.info{
			width: 100%;
			color: #333;
			font-size: 12px;
			line-height: 1.5;

			.infoTxtP{
				height: 12px * 1.5;
				overflow: hidden;
			}

			.infoBtn{
				display: inline-block;
				width: 135px;
				position: relative;
				padding-left: 60px;
				float: right;
				font-size: 12px;
				height: 12px;

				span{
					font-size: 13px;
					color: #a39679;
				}

				&:before{
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border-right: 1px solid #a39679;
					border-bottom: 1px solid #a39679;
					position: absolute;
					transform: rotate(45deg);
					top: 1px;
					left: 40px;
				}

				&.opened{
					span{
						color: $c_hov;
					}
					&:before{
						border-right: none;
						border-bottom: none;
						border-left: 1px solid $c_hov;
						border-top: 1px solid $c_hov;
						top: 9px;
					}
				}
			}
		}
	}
	@if $type == pc{
		display: table;
		color: #333;
		height: 20px;
		border-bottom: 1px solid #999;

		h1{
			font-family: $ff_mincho;
			font-size: 20px;
			line-height: 1;
			white-space: nowrap;
			display: table-cell;
		}

		.info{
			display: table-cell;
			padding: 0 20px;
			padding-bottom: 15px;

			p{
				font-size: 13px;
				line-height: 20px;
				overflow: hidden;
				height: 20px;
			}
		}

		.infoBtn{
			display: table-cell;
			width: 94px;
			position: relative;
			padding-left: 20px;
			cursor: pointer;

			span{
				font-size: 13px;
				color: #a39679;
			}

			&:before{
				content: "";
				display: block;
				width: 13px;
				height: 13px;
				border-right: 1px solid #a39679;
				border-bottom: 1px solid #a39679;
				position: absolute;
				transform: rotate(45deg);
				top: 1px;
				left: 0;
			}

			&:hover{
				span{
					color: $c_hov;
				}
				&:before{
					border-right: 1px solid $c_hov;
					border-bottom: 1px solid $c_hov;
				}
			}

			&.opened{
				span{
					color: $c_hov;
				}
				&:before{
					border-right: none;
					border-bottom: none;
					border-left: 1px solid $c_hov;
					border-top: 1px solid $c_hov;
					top: 9px;
				}
			}
		}
	}
}

.productInfo{
	@if $type == sp{
		padding-top: 30px;
		margin-bottom: 40px;

		.productImg{
			width: 100%;

			img{
				width: 100%;
				height: auto;
			}
		}

		.productSpec{
			width: 100%;

			.productName{
				margin-bottom: 20px;
				position: relative;

				.tag{
					font-size: 12px;
					line-height: 1;
					color: #a39679;
					border: 1px solid #a39679;
					display: table;
					padding: 3px 5px;
					border-radius: 3px;
					position: absolute;
					top: 10px;
					right: 0;
				}

				.productTitle{
					font-size: 18px;
					line-height: 1.3;
					padding: 10px 0;
					color: #333;
					padding-right: 50px;
				}

				.produce{
					color: #a39679;
					font-size: 13px;
					line-height: 1;
					position: relative;
					padding-right: 30px;

					.itemHeart{
						width: 24px;
						height: 22px;
						position: absolute;
						top: 0;
						right: 0;

						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}

			.aboutProductTitle{
				font-size: 18px;
				display: table;
				border-bottom: 1px solid #999;
				padding-bottom: 5px;
				margin-bottom: 20px;
				font-family: serif;
				line-height: 1;
				color: #333;
				padding-top: 40px;
			}

			.aboutProduct{
				font-size: 14px;
				color: #333;
				line-height: 1.6;
				margin-bottom: 40px;
			}

			.options{
				select{
					width: 100%;
					margin-bottom: 10px;
				}
				margin-bottom: 20px;
			}

			.splitBlock{
				margin-bottom: 10px;

				&:after{
					content: "";
					display: block;
					clear: both;
					width: 1px;
					height: 0;
					overflow: hidden;
				}

				.productPrice{
					color: #b32e38;
					font-size: 22px;
					float: left;
					width: 50%;


					.yen{
						font-size: 14px;
						color: #b32e38;
					}

					.tax{
						font-size: 13px;
						color: #848484;
					}
				}

				.order{
					float: right;
					width: 50%;

					.spinnerWrap{
						margin: 0 0 0 5px;
						width: 98px;
					}
				}
			}

			.d4OrderBtm{
				text-align: center;
				margin-bottom: 20px;

				.commonBtn{
					width: 243px;
					margin-right: 10px;
				}
			}
		}

		&.d4Reorder{
			padding-top: 0;
			margin-bottom: 0;
			clear: both;

			.hart{
				display: block;
				text-align: center;
				color: #816d3f;
				vertical-align: middle;
				padding-top: 10px;

				img{
					width: 24px;
					height: auto;
					vertical-align: middle;
					padding-right: 5px;
				}
			}
		}
	}
	@if $type == pc{
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		margin-bottom: 40px;

		.productImg{
			width: 480px;
			img{
				width: 480px;
				height: auto;
			}
			a{
				&:hover img{
					opacity: 0.8;
				}
			}
		}

		.productSpec{
			width: 480px;

			.productName{
				margin-bottom: 40px;

				.tag{
					font-size: 12px;
					line-height: 1;
					color: #a39679;
					border: 1px solid #a39679;
					display: inline-block;
					padding: 3px 5px;
					border-radius: 3px;
				}

				.productTitle{
					font-size: 24px;
					line-height: 1.3;
					padding: 10px 0;
					color: #333;
				}

				.produce{
					color: #848484;
					font-size: 14px;
					line-height: 1;
				}
			}

			.aboutProduct{
				font-size: 14px;
				color: #333;
				line-height: 1.6;
				margin-bottom: 40px;
			}

			.options{
				select{
					width: 237px;
					margin-bottom: 10px;
				}
				margin-bottom: 20px;
			}

			.productPrice{
				color: #b32e38;
				font-size: 26px;
				margin-bottom: 15px;

				.yen{
					font-size: 20px;
					color: #b32e38;
				}

				.tax{
					font-size: 16px;
					color: #848484;
				}
			}

			.order{
				margin-bottom: 40px;

				.spinnerWrap{
					margin: 0 20px 0 10px;
					width: 98px;
				}

				.commonBtn{
					width: 255px;
					margin-right: 10px;
				}
			}

			.productMemo{
				width: 480px;
				background-color: #f5f2ea;
				text-align: center;
				padding: 20px 0;

				.telNum{
					font-size: 24px;
					color: #a39679;
				}
			}
		}
	}
}

.productDetails{
	@if $type == sp{
		padding-bottom: 15px;

		dl{
			margin-bottom: 15px;

			dt{
				font-size: 16px;
			}

			dd{
				font-size: 13px;

				&.tags{

					span{
						display: inline-block;
						background-color: #eee;
						color: #666;
						border-radius: 3px;
						padding: 3px 5px;
						margin-right: 5px;
						line-height: 1;
					}
				}
			}
		}
	}
	@if $type == pc{
		display: flex;
		justify-content: space-between;

		.productContents{
			width: 480px;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;

			dl{
				display: table;
				width: 480px;
				font-size: 14px;
				color: #333;

				dt, dd{
					display: table-cell;
					vertical-align: top;
					padding: 20px;
				}

				dt{
					width: 135px;
				}
			}
		}

		.others{
			width: 480px;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;

			dl{
				display: table;
				width: 480px;
				font-size: 14px;
				color: #333;

				&:not(:last-of-type){
					border-bottom: 1px solid #ccc;
				}

				dt, dd{
					display: table-cell;
					vertical-align: top;
					padding: 20px;
				}

				dt{
					width: 130px;
				}

				dd{

					&.tags{

						span{
							display: inline-block;
							background-color: #eee;
							color: #666;
							border-radius: 3px;
							padding: 3px 5px;
							margin-right: 5px;
							line-height: 1;
						}
					}
				}
			}
		}
	}
}

.shopDetailTitle{
	@if $type == sp{
		display: table;
		font-size: 18px;
		font-family: serif;
		color: #333;
		padding-bottom: 5px;
		line-height: 1;
		border-bottom: 1px solid #999;
		margin: 0 10px 20px;

		&.mb15{
			margin-bottom: 15px;
		}
	}
}

.shopDetailTxt{
	@if $type == sp{
		margin: 0 10px 15px;

		dl{
			margin-bottom: 15px;

			dt{
				font-size: 18px;
			}

			dd{
				font-size: 13px;
			}
		}
	}
}

.shopDtailInfo{
	@if $type == sp{
		margin: 0 10px 15px;

		.deliveryMap{
			border: 1px solid #ccc;
			margin-bottom: 15px;

			h4{
				background-color: #eee;
				border-bottom: 1px solid #ccc;
				text-align: center;
				font-size: 16px;
				line-height: 1;
				padding: 10px 0;
				font-weight: 400;
				display: block;
				margin-bottom: 0;
			}
		}

		h4{
			background-color: #eee;
			font-size: 16px;
			line-height: 1;
			padding: 10px;
			font-weight: 400;
			display: table;
			margin-bottom: 10px;
		}

		p{
			margin-bottom: 15px;

			&.sonota{
				font-size: 12px;
			}
		}
		.mapImg{
			img{
				@if $type == pc{
				}
				@if $type == sp{
					width: 100%;
				}
			}
		}
	}
}

.tabBody .shopDetailTxt,
.tabBody .shopDtailInfo{
	margin-left: 0;
	margin-right: 0;
}

.hosoku{
	@if $type == sp{
		margin: 0 10px 15px;

		h4{
			font-size: 16px;
		}
	}
}

.productMemo{
	@if $type == sp{
		background-color: #f5f2ea;
		text-align: center;
		padding: 10px 0;
		margin: 10px 0 15px;
		font-size: 12px;
		color: #333;

		.telNum{
			font-size: 18px;
			color: #a39679;
		}

		.kome{
			font-size: 10px;
		}
	}
}

.productContact{
	@if $type == sp{
		background-color: #f6f6f6;
		margin: 0 0 15px;
		padding: 10px;
		text-align: center;
		font-size: 12px;
		line-height: 2;

		button{
			width: 172px;
		}
	}
}

.best5{
	@if $type == sp{
		background-image: url(../images/second/sp-d4-rank-bg.jpg);
		background-repeat: repeat-y;
		background-size: 25px auto;
		background-position: 0 0;
		background-color: #f5f5f5;
		margin-bottom: 20px;
		padding: 15px 10px;

		h2, h3{
			font-size: 16px;
			color: #333;
			margin: 0 10px 10px;
		}

		.sclBlock{
			overflow-x: scroll;
		}

		.ranking{
			display: table;
			border-collapse: separate;
			border-spacing: 10px 0;

			.rank{
				display: table-cell;
				width: 225px;

				.rankImg{
					width: 225px;

					img{
						width: 100%;
						height: auto;
					}
				}

				.rankTxt{

					.rankName{
						font-size: 14px;
						color: #b32e38;
						display: table;

						.name{
							display: table-cell;
							width: 98%;
						}

						.tag{
							display: table-cell;
							padding-left: 10px;

							span{
								display: block;
								color: #a39679;
								border: 1px solid #a39679;
								border-radius: 3px;
								padding: 3px 5px;
								white-space: nowrap;
								font-size: 10px;
								line-height: 1;
							}
						}
					}

					.price{
						font-size: 12px;
						line-height: 1;
						color: #333;
					}

					.yen{
						font-size: 10px;
					}

					.tax{
						font-size: 8px;
						color: #848484;
					}

					.order{
						display: table;
						width: 100%;
						margin-bottom: 10px;

						.count{
							display: table-cell;
							text-align: right;
							padding-right: 5px;
						}

						.spinnerWrap{
							display: table-cell;
							width: 130px;

							input{
								width: 100%;
							}
						}
					}

					.rankOrderBtm{

						.commonBtn{
							width: 147px;
						}

						.hart{
							width: 24px;
							margin-left: 23px;
							display: inline-block;

							img{
								width: 100%;
								height: auto;
							}
						}
					}
				}
			}
		}

		&.rankOrder{
			.rank{
				position: relative;

				&:before{
					width: 37px;
					height: 34px;
					display: block;
					position: absolute;
					z-index: 3;
					top: 0;
					left: 0;
					background-color: #bababa;
					line-height: 34px;
					text-align: center;
					color: #fff;
					font-size: 12px;
				}

				&:after{
					content: "";
					width: 1px;
					height: 0px;
					border-top: 17px solid #bababa;
					border-bottom: 17px solid #bababa;
					border-right: 6px solid transparent;
					display: block;
					position: absolute;
					top: 0;
					left: 37px;
				}

				&:nth-of-type(1):before{
					content: "1";
					background-color: #a09174;
				}

				&:nth-of-type(2):before{
					content: "2";
					background-color: #8b8b8b;
				}

				&:nth-of-type(3):before{
					content: "3";
					background-color: #9f6c66;
				}

				&:nth-of-type(4):before{
					content: "4";
				}

				&:nth-of-type(5):before{
					content: "5";
				}

				&:nth-of-type(1):after{
					border-top: 17px solid #a09174;
					border-bottom: 17px solid #a09174;
				}

				&:nth-of-type(2):after{
					border-top: 17px solid #8b8b8b;
					border-bottom: 17px solid #8b8b8b;
				}

				&:nth-of-type(3):after{
					border-top: 17px solid #9f6c66;
					border-bottom: 17px solid #9f6c66;
				}
			}
		}
	}
}


.d4BgGray{
	@if $type == sp{
	}
	@if $type == pc{
		background: url(../images/common/f_bg.png) repeat center top;
		padding: 50px 0;

		.shopDetailTitle{
			width: 990px;
			margin: 30px auto 0;

			h2{
				font-size: 20px;
				line-height: 1;
				display: table;
				border-bottom: 1px solid #999;
				padding-bottom: 13px;
				margin-bottom: 20px;
				font-family: $ff_mincho;
			}
		}
		.shopDetaiTab{
			width: 990px;
			margin: 0 auto;
			display: block;

			li{
				display: inline-block;
				font-size: 12px;
			}
		}

		.bgInInner{
			padding: 0 30px;

			h3{
				font-size: 22px;
				border-bottom: 1px solid #ccc;
				line-height: 1;
				padding-bottom: 20px;
				margin-bottom: 20px;
				font-weight: 400;
				color: #333;
			}

			.hosoku{
				width: 100%;
				clear: both;
				border: 1px solid #ccc;
				padding: 30px;

				h4{
					font-size: 15px;
					font-weight: 400;
					margin-bottom: 10px;
				}

				p{
					font-size: 13px;
				}
			}

			.shopDetailTxt{
				width: 597px;
				float: left;
				margin-bottom: 20px;

				ul{

					li{
						border-bottom: 1px solid #ccc;
						padding: 5px 0;

						dl{
							display: table;
							width: 100%;
							color: #333;

							&.bg-f7f3ee{
								background-color: #f7f3ee;
							}

							dt, dd{
								display: table-cell;
								font-size: 14px;
								padding: 15px 0;
							}

							dt{
								width: 165px;
								padding-left: 15px;
							}

							dd{

								.fz12{
									font-size: 12px;
								}

								.telNum{
									font-size: 22px;
									color: #a39679;
								}
							}
						}
					}
				}
			}

			.shopDtailInfo{
				width: 302px;
				float: right;
				margin-bottom: 20px;

				.deliveryMap{
					border: 1px solid #ccc;
					margin-bottom: 20px;

					h4{
						font-size: 15px;
						text-align: center;
						background-color: #eee;
						height: 40px;
						line-height: 40px;
						color: #333;
						border-bottom: 1px solid #ccc;
						font-weight: 400;
						margin-bottom: 0;
					}
				}

				h4{
					font-size: 15px;
					background-color: #eee;
					height: 40px;
					line-height: 40px;
					color: #333;
					padding: 0 10px;
					margin-bottom: 10px;
					font-weight: 400;
				}

				p{
					font-size: 15px;
					color: #333;
					margin-bottom: 20px;

					&.sonota{
						font-size: 13px;
					}
				}
			}
		}
	}
}

.best5{
	@if $type == sp{
	}
	@if $type == pc{

		h2{
			display: table;
			font-size: 20px;
			font-family: $ff_mincho;
			border-bottom: 1px solid #999;
			margin-bottom: 25px;
			padding-bottom: 13px;
			line-height: 1;
			color: #333;
			margin-top: 80px;
		}

		.ranking{
			display: flex;
			justify-content: space-between;

			.rank{
				width: 177px;

				a{
					display: block;
					width: 100%;
					text-decoration: none;

					.rankImg{
						margin-bottom: 10px;
					}

					.rankTxt{

						.rankName{
							font-size: 12px;
							display: block;
						}

						.price{
							font-size: 14px;
							color: #333;
						}

						.yen{
							font-size: 12px;
							color: #333;
						}

						.tax{
							font-size: 12px;
							color: #848484;
						}
					}

					&:hover{

						.rankImg{
							opacity: .7;
						}

						.rankTxt{
							text-decoration: none;

							.rankName{
								color: #b32e38;
							}
						}
					}
				}
			}
		}

		&.rankOrder{
			.rank{
				position: relative;

				&:before{
					width: 37px;
					height: 26px;
					display: block;
					position: absolute;
					z-index: 3;
					top: 0;
					left: 0;
					background-color: #bababa;
					line-height: 26px;
					text-align: center;
					color: #fff;
					font-size: 12px;
				}

				&:after{
					content: "";
					width: 1px;
					height: 0px;
					border-top: 13px solid #bababa;
					border-bottom: 13px solid #bababa;
					border-right: 13px solid transparent;
					display: block;
					position: absolute;
					top: 0;
					left: 37px;
				}

				&:nth-of-type(1):before{
					content: "1";
					background-color: #a09174;
				}

				&:nth-of-type(2):before{
					content: "2";
					background-color: #8b8b8b;
				}

				&:nth-of-type(3):before{
					content: "3";
					background-color: #9f6c66;
				}

				&:nth-of-type(4):before{
					content: "4";
				}

				&:nth-of-type(5):before{
					content: "5";
				}

				&:nth-of-type(1):after{
					border-top: 13px solid #a09174;
					border-bottom: 13px solid #a09174;
				}

				&:nth-of-type(2):after{
					border-top: 13px solid #8b8b8b;
					border-bottom: 13px solid #8b8b8b;
				}

				&:nth-of-type(3):after{
					border-top: 13px solid #9f6c66;
					border-bottom: 13px solid #9f6c66;
				}
			}
		}
	}
}


/* ---------------------- D-11 ------------------- */

.d11Top{
	@if $type == sp{
		width: 100%;
		position: relative;

		.shopImg{
			width: 100%;

			img{
				width: 100%;
				height: auto;
			}
		}

		h1{
			font-size: 17px;
			color: #fff;
			font-family: serif;
			background-color: rgba(#a39679, .95);
			padding: 5px 10px;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}
	@if $type == pc{
		width: 100%;
		margin-top: -110px;
		position: relative;
		height: 340px;
		background-color: #f6f4eb;

		h1{
			background-color: #a39679;
			color: #fff;
			font-family: $ff_mincho;
			font-size: 28px;
			line-height: 1.3;
			padding: 20px;
			position: absolute;
			top: 110px;
			width: 100%;
			z-index: 3;
		}

		.shopImg{
			width: 50%;
			height: 340px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			overflow: hidden;
			img{
				width: 100%;
				min-width: 600px;
			}
		}
		.shopImgCover{
			width: 100%;
			max-width: 1200px;
			min-width: 990px;
			text-align: left;
			position: relative;
			z-index: 3;
			overflow: hidden;
		}

		.d11TopItem{
			height: 340px;
			width: 100%;
			position: absolute;
			top: 0;
			z-index: 4;

			.secondHeadFilter2{
				width: 643px;
				height: 96px;
				position: absolute;
				bottom: 20px;
				right: 20px;
				background-color: rgba(#fff, .8);
				display: table;

				.secondHeadFilter2Cell{
					display: table-cell;
					padding-top: 19px;
					vertical-align: top;

					&:nth-of-type(1){
						padding-left: 20px;
					}

					&:nth-of-type(2){
						padding-left: 10px;
					}

					&:nth-of-type(3){
						padding-left: 10px;
						padding-top: 36px;
						padding-right: 20px;
					}

					span{
						display: block;
						color: #333;
						font-size: 13px;
						line-height: 1;
						margin-bottom: 9px;
					}

					select{
						width: 235px;
					}

					.commonBtn{
						width: 116px;
					}
				}
			}
			.secondHeadFilter3{
				width: 900px;
				height: 76px;
				position: absolute;
				bottom: 20px;
				right: 20px;
				background-color: rgba(#fff, .8);
				display: table;

				.secondHeadFilter2Cell{
					display: table-cell;
					padding-top: 19px;
					vertical-align: bottom;
					padding-bottom: 20px;

					&:nth-of-type(1){
						padding-left: 20px;
					}

					&:nth-of-type(2){
						padding-left: 20px;
					}
					&:nth-of-type(3){
						padding-left: 10px;
					}
					&:nth-of-type(4){
						padding-left: 10px;
						padding-right: 20px;
						padding-bottom: 15px;
					}

					span{
						display: block;
						color: #333;
						font-size: 13px;
						line-height: 1;
						margin-bottom: 9px;
					}

					select{
						width: 235px;
					}

					.commonBtn{
						width: 116px;
					}
				}
			}
		}
	}
}

.secondHeadFilter2{
	@if $type == sp{
		margin: 0 10px 15px;

		.secondHeadFilter2Cell{

			select{
				width: 100%;
			}

			&:nth-of-type(1){
				width: 49%;
				float: left;
			}

			&:nth-of-type(2){
				width: 49%;
				float: right;
			}

			&:nth-of-type(3){
				width: 100%;
				clear: both;
				text-align: center;
				padding-top: 10px;

				.commonBtn{
					width: 243px;
				}
			}
		}
	}
}

.d11ContentsHead{
	@if $type == sp{
		border-bottom: 1px solid #999;
		margin: 0 10px 15px;

		h2{
			font-size: 22px;
			font-family: serif;
			color: #333;
		}

		&.withHart{
			position: relative;

			h2{
				padding-right: 30px;
			}

			.itemHeart{
				width: 24px;
				height: 22px;
				position: absolute;
				top: 5px;
				right: 0;
			}
		}
	}
	@if $type == pc{
		border-bottom: 1px solid #999;
		width: 100%;
		margin-bottom: 30px;

		&:after{
			content: "";
			display: block;
			clear: both;
			width: 1px;
			height: 0;
			overflow: hidden;
		}

		h2{
			font-size: 20px;
			font-family: $ff_mincho;
			float: left;
			color: #333;
		}

		.itemHeart{
			float: right;
			font-size: 14px;
			display: block;
			padding-left: 30px;
			height: 23px;
			line-height: 23px;
			background-repeat: no-repeat;
			text-decoration: none;

			&:hover{
				background-image: url(../images/second/heart_hover.png);
				color: $c_hov;
			}
		}
	}
}

.d11Point{
	@if $type == sp{
		margin: 0 10px 40px;

		.pointImg{
			width: 100%;
			position: relative;

			img{
				width: 100%;
				height: auto;
				box-shadow: 0px 1px 5px rgba(0,0,0,0.5);
			}

			&:after{
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 164px;
				height: 55px;
				z-index: 10;
				background-size: 164px 54px;
			}

			&.point1:after{
				background-image: url(../images/second/ribon-point1.png);
			}

			&.point2:after{
				background-image: url(../images/second/ribon-point2.png);
			}

			&.point3:after{
				background-image: url(../images/second/ribon-point3.png);
			}
		}

		.pointTxt{
			color: #333;
			margin: 40px 10px 10px;

			h2{
				font-size: 18px;
				display: table;
				padding: 0 0 5px;
				margin-bottom: 10px;
				border-bottom: 1px solid #999;
				font-family: serif;
			}

			p{
				font-size: 16px;
			}
		}
	}
	@if $type == pc{
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;

		.pointImg{
			width: 480px;
			position: relative;

			img{
				box-shadow: 0px 1px 5px rgba(0,0,0,0.5);
			}

			&:after{
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 164px;
				height: 54px;
				z-index: 10;
			}

			&.point1:after{
				background-image: url(../images/second/ribon-point1.png);
			}

			&.point2:after{
				background-image: url(../images/second/ribon-point2.png);
			}

			&.point3:after{
				background-image: url(../images/second/ribon-point3.png);
			}
		}

		.pointTxt{
			width: 480px;
			color: #333;

			h2{
				font-size: 20px;
				display: table;
				padding-bottom: 5px;
				margin-bottom: 10px;
				border-bottom: 1px solid #999;
				font-family: $ff_mincho;
			}

			p{
				font-size: 14px;
			}
		}
	}
}

.shopDetaiTab{
	@if $type == sp{
		overflow-x: scroll;

		ul{
			display: table;
			border-bottom: 1px solid #ccc;
			height: 65px;

			li{
				display: table-cell;
				padding-right: 5px;
				padding-top: 15px;

				a{
					width: 79px;
					height: 50px;
					display: block;
					background-color: #eaeaea;
					border-radius: 5px 5px 0 0;
					text-align: center;
					font-size: 12px;
					line-height: 1;
					padding-top: 19px;
				}

				&.now{
					a{
						border-top: 1px solid #ccc;
						border-right: 1px solid #ccc;
						border-left: 1px solid #ccc;
						background-color: #fff;
						color: #b32e38;
						position: relative;

						&:after{
							content: "";
							width: 100%;
							height: 3px;
							background-color: #b32e38;
							left: 0;
							bottom: -2px;
							display: block;
							position: absolute;
						}
					}
				}
			}
		}
	}
}

.handled{
	@if $type == sp{
	}
	@if $type == pc{
		padding-top: 80px;
		padding-bottom: 0;

		.productsOfHandled{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			&.mbm60{
				margin-bottom: -60px;
			}
		}
	}
}

.omakaseTop{
	@if $type == pc{
		width: 100%;
		margin-top: -110px;
		position: relative;
		height: 465px;
		background-color: #fff;
	}
	@if $type == sp{
		width: 100%;
		position: relative;
	}
	.shopImg{
		@if $type == pc{
			width: 65%;
			min-width: 720px;
			max-width: 1000px;
			height: 465px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2;
			overflow: hidden;
		}
		@if $type == sp{
			width: 100%;
		}

		img{
			@if $type == pc{
				width: 100%;
				min-width: 820px;
			}
			@if $type == sp{
				width: 100%;
				height: auto;
			}
		}
	}//.shopImg
	.shopImgCover{
		@if $type == pc{
			width: 100%;
			max-width: 1200px;
			min-width: 990px;
			padding-top: 125px;
			margin: 0 auto;
			text-align: center;
			position: relative;
			z-index: 3;
			overflow: hidden;
		}
		@if $type == sp{
		}
		img{
			@if $type == pc{
				margin-left: 5%;
			}
			@if $type == sp{
			}
		}
	}
}//.omakaseTop

.clearBoth{
	clear: both;
	width: 1px;
	height: 0;
	overflow: hidden;
}



.sample{
	@if $type == sp{
	}
	@if $type == pc{
	}
}
