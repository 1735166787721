// 変数設定

// Break Point ---------------------------------------------
//$breakpoint_sp    :500px;
//$breakpoint_tb    :800px;
//$breakpoint_pc    :$breakpoint_sp + 1;

// Color ---------------------------------------------------
// font、backgroundやborderの色
// 頭文字"c_"から命名するといいかもよ
$c_base : #0663b8;//サイトカラー（メインの色）カテゴリごとにあるなら「cc_news」(CategoryColor_'categoryName')ってすると良いかもね
$c_text : #666; // テキストに使うフォントカラー
$c_href : #a39679; // テキスト内で使うリンクカラー
$c_hov : #ab1f24; // リンクホバー色

$c_red : #b32e38;

// width ---------------------------------------------------
$site_w: 990px !default;

// Font Family
// フォントファミリー
// colorが「c_」だからfont-familyは"ff_"から命名しときましょう
$ff  : YuGothic, 'Yu Gothic','Meiryo','Hiragino Kaku Gothic ProN',sans-serif; //NotoSans
$ff_min  :  YuMincho,'Yu Mincho',serif;

@mixin hov_line{
  a:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: rgba(#ab1f24,0.7);
    transition: ease .5s;
    z-index: 100;
  }

  &:hover{
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5);
    transition: ease .5s;
  }

  &:hover a:after{
    width: 100%;
  }
}
