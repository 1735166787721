
.home_area{
	background: url(../images/common/f_bg.png) repeat center top;
	padding: 40px 0;
	@if $type == sp{
		background: none;
		padding: 0;
	}

	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.area_areaSearch{
		width: 480px;
		padding-right: 60px;
		overflow: hidden;
		@if $type == sp{
			width: 100%;
			padding: 0;
		}

		h2{
			@if $type == pc{
				float: right;
			}
			@if $type == sp{
				margin-left: 10px;
			}
		}

		.tabMenu{
			@if $type == pc{
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				flex-direction: row;
				width: 340px;
				margin-top: 15px;
			}
			@if $type == sp{
				overflow-y:scroll;
				white-space: nowrap;
				border-bottom: 1px solid #cccccc;
				font-size: 0;
			}

			li{
				@if $type == pc{
					flex-basis: 64px;
					width: 64px;
					height: 35px;
					line-height: 35px;
					font-size: 12px;
					margin-right: 5px;
					text-align: center;
				}
				@if $type == sp{
					display: inline-block;
					margin-right: 5px;
					font-size: 12px;
				}

				&:last-child{
					margin-right: 0;
				}
			}

			span{
					display: block;
					cursor: pointer;
					background: #efefef;
					border: 1px solid #ccc;
					border-bottom: none;
					border-radius: 3px 3px 0 0;
					color: $c_href;
				@if $type == pc{
					height: 35px;
				}
				@if $type == sp{
					height: 40px;
					padding: 10px 20px 0;
				}
			}

			li.active span{
				background: #fff;
				color: $c_hov;
				z-index: 10;
				position: relative;
				@if $type == sp{
					box-shadow: 0px 0px 5px 0px rgba(100,100,100,0.5);
				}

				&:after{
					content:"";
					width: 100%;
					height: 2px;
					background: $c_hov;
					bottom: 0;
					left: 0;
					position: absolute;
				}
			}
		}

		.tabBody{
			float: left;
			background: #fff;
			letter-spacing: -0.4em;
			border-radius: 0 0 3px 3px;
			@if $type == pc{
				width: 340px;
				padding: 20px 20px 0;
			}
			@if $type == sp{
				padding: 15px 10px 0;
			}
			&.content{
				display: none;
				&:nth-child(1) {
					display: block;
				}
			}

			li{
				display: inline-block;
				letter-spacing: normal;
				@if $type == pc{
					margin: 0 10px 20px 0;
				}
				@if $type == sp{
					margin: 0 10px 10px 0;
				}
			}

			li a{
				display: block;
				padding: 10px 13px;
				background: #f5f2ea;
				color: #996633;
				border: 1px solid #f5f2ea;
				border-radius: 20px;

				&:hover{
					color: $c_hov;
					background: #fff;
					border: 1px solid $c_hov;
				}
			}
		}
	}

	.area_mapSearch{
		width: 480px;
		@if $type == sp{
			display: none;
		}

		h2{
			float: right;
			padding-top: 100px;
		}

		.area_map{
			position: relative;
		}

		.map_list{
			position: absolute;
			width: 410px;
			height: 410px;
			top: 0;
			left: 0;
			background: url("../images/home/area_map/map_.png") no-repeat 0 0 #fff;
			position: relative;

			&.map1{background: url("../images/home/area_map/map_kyusyu.png") no-repeat 0 0;}
			&.map2{background: url("../images/home/area_map/map_kansai.png") no-repeat 0 0;}
			&.map3{background: url("../images/home/area_map/map_toukai.png") no-repeat 0 0;}
			&.map4{background: url("../images/home/area_map/map_kanto.png") no-repeat 0 0;}
			&.map5{background: url("../images/home/area_map/map_hokkaido.png") no-repeat 0 0;}

			& > li{
				position: absolute;

				span{
					display: none;
				}
			}

			& > li > ul{
				background: url(../images/common/hukidashi.png) no-repeat center bottom;
				width: 296px;
				padding: 20px 0 15px 20px;
				letter-spacing: -0.4em;
				display: none;
				border-radius: 5px 5px 0 0;
				border-top: 1px solid #f0f0f0;
			}

			li.disp{
				z-index: 3;
			}
			li.disp ul{
				display: block;
			}

			.on_area{
				display: block;
				position: absolute;
				cursor: pointer;
			}
			.on_area1{
				width: 54px;
				height: 74px;
				top: 313px;
				left: 46px;

				& > ul{
					position: relative;
					top: -156px;
					left: -119px;
				}
			}
			.on_area2{
				width: 45px;
				height: 57px;
				top: 273px;
				left: 138px;

				& > ul{
					background: url(../images/common/hukidashi_s2.png) no-repeat center bottom;
					width: 279px;
					position: relative;
					top: -156px;
					left: -110px;
				}
			}
			.on_area3{
				width: 65px;
				height: 67px;
				top: 259px;
				left: 170px;

				& > ul{
					background: url(../images/common/hukidashi_s.png) no-repeat center bottom;
					width: 242px;
					padding-left: 10px;
					text-align: center;
					position: relative;
					top: -95px;
					left: -90px;
				}
			}
			.on_area4{
				width: 50px;
				height: 56px;
				top: 241px;
				left: 217px;

				& > ul{
					background: url(../images/common/hukidashi_s.png) no-repeat center bottom;
					width: 242px;
					padding-left: 10px;
					text-align: center;
					position: relative;
					top: -95px;
					left: -90px;
				}
			}
			.on_area5{
				width: 117px;
				height: 112px;
				top: 27px;
				left: 245px;

				& > ul{
					padding-left: 10px;
					background: url(../images/common/hukidashi_s3.png) no-repeat center bottom;
					width: 132px;
					text-align: center;
					position: relative;
					top: -95px;
					left: -11px;
				}
			}

			& > li > ul > li{
				display: inline-block;
				letter-spacing: normal;
				margin: 0 10px 20px 0;
			}

			& > li > ul > li a{
				display: block;
				padding: 10px 13px;
				background: #f5f2ea;
				color: #996633;
				border: 1px solid #f5f2ea;
				border-radius: 20px;

				&:hover{
					color: $c_hov;
					background: #fff;
					border: 1px solid $c_hov;
				}
			}
		}

		.kyusyu{
			bottom: 95px;
			left: -70px;
		}

		.kansai{
			bottom: 150px;
			right: 110px;
		}

		.kantou{
			bottom: 160px;
			right: 20px;
		}

		.toukai{
			bottom: 150px;
			right: 50px;
		}

		.hokkaido{
			top: -75px;
			right: -25px;
		}
	}
}

.home_category{
	@if $type == pc{
		padding-top: 60px;
		margin-bottom: 60px;
		box-shadow: 0px 5px 5px 0px rgba(204,204,204,0.8);
		min-width: $site_w;
	}
	@if $type == sp{
		padding: 0;
	}

	h2{
		@if $type == pc{
			margin-bottom: 30px;
			text-align: center;
		}
		@if $type == sp{
			margin-left: 10px;
		}
	}

	.tabMenu{
			@if $type == pc{
				text-align: center;
				display: flex;
				justify-content: center;
				position: relative;
			}
			@if $type == sp{
				overflow-y:scroll;
				white-space: nowrap;
				border-bottom: 1px solid #cccccc;
				font-size: 0;
			}
			&:after{
				content: "";
				display: block;
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
				height: 1px;
				background: #ccc;
			}

			li{
				@if $type == pc{
					flex-basis: 64px;
					width: 64px;
					height: 35px;
					line-height: 35px;
					font-size: 12px;
					margin-right: 5px;
					text-align: center;
				}
				@if $type == sp{
					display: inline-block;
					margin-right: 5px;
					font-size: 12px;
				}

				&:last-child{
					margin-right: 0;
				}
			}

			li span{
				cursor: pointer;
				display: block;
				background: #efefef;
				border: 1px solid #ccc;
				border-bottom: none;
				color: $c_href;
				@if $type == pc{
					height: 35px;
					border-radius: 3px 3px 0 0;
				}
				@if $type == sp{
					height: 40px;
					padding: 10px 20px 0;
					border-radius: 3px 3px 0 0;
				}
			}

		li.active span{
			background: #fff;
			border-bottom: none;
			color: $c_hov;
			z-index: 10;
			position: relative;

			&:after{
				content:"";
				width: 100%;
				height: 2px;
				background: $c_hov;
				bottom: 0;
				left: 0;
				position: absolute;
			}
		}
	}

	.tabBody{
		@if $type == pc{
			padding: 30px 0;
		}
		@if $type == sp{
			padding: 10px;
		}
		&.content{
			display: none;
			&:nth-child(1) {
				display: block;
			}
		}

		ul{
			@if $type == pc{
				display: flex;
				justify-content: space-between;
			}
			@if $type == sp{
			}
		}

		li{
			@if $type == pc{
				flex-basis: 32%;
				width: 32%;
				position: relative;
				height: 170px;
				background: #fff;
				justify-content: space-between;
				box-sizing: border-box;
				transition: ease .5s;
				@include hov_line;
			}
			@if $type == sp{
				margin-bottom: 10px;
				transition: ease .5s;
				@include hov_line;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}

		a{
			@if $type == pc{
				display: block;
				height: 170px;
				overflow: hidden;
				position: relative;
			}
			@if $type == sp{
				display: block;
				overflow: hidden;
				position: relative;
				height: 125px;
				border-radius: 0;
			}
			&:hover{
				@if $type == pc{
				}
				@if $type == sp{
					border:none;
				}
			}
		}

		img:first-child{
			width: 100%;
		}

		img + img{
			@if $type == pc{
				position: absolute;
				top: 50%;
				right: 15px;
				margin-top: -69px;
			}
			@if $type == sp{
				position: absolute;
				width: 105px;
				top: 10px;
				right: 10px;
			}
		}
	}
}

.home_newShop{
	@if $type == pc{
		margin: 0 auto 100px;
		width: $site_w;
	}
	@if $type == sp{
		margin: 0;
	}

	h2{
		@if $type == pc{
			margin-bottom: 35px;
			text-align: center;
		}
		@if $type == sp{
			margin-left: 10px;
		}
	}

	.scroll_wrap{
		@if $type == pc{
		}
		@if $type == sp{
			width: 100%;
			overflow-y: scroll;
			padding-left: 10px;
		}
	}

	ul{
		@if $type == pc{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@if $type == sp{
			white-space: nowrap;
		}
	}

	li{
		@if $type == pc{
			flex-basis: 172px;
			width: 172px;
		}
		@if $type == sp{
			width: 40%;
			display: inline-block;
			margin-right: 7px;
		}
	}

	dt{
		margin-bottom: 10px;
		position: relative;
		@if $type == sp{
			margin-bottom: 5px;
		}

		span{
			background: #99886b;
			font-size: 12px;
			color: #fff;
			text-align: center;
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 70px;
			height: 28px;
			line-height: 28px;
			z-index: 0;

			&:after{
				content: "";
				position: absolute;
				width: 14px;
				height: 14px;
				display: block;
				border-top: 14px solid #99886b;
				border-right: 14px solid transparent;
				border-bottom: 14px solid #99886b;
				border-left: 14px solid #99886b;
				top: 0;
				right: -14px;
				z-index: -1;
			}
		}
	}

	dd{
		text-align: center;
		padding-bottom: 5px;
		font-size: 12px;
		display: block;
		@if $type == sp{
			white-space: initial;
			line-height: 1.3;
		}

		a{
			display: block;
			border-bottom: 1px solid $c_href;
			padding-bottom: 5px;

			&:hover{
				text-decoration: none;
				border-bottom: 1px solid $c_hov;
			}
		}
	}
}


.home_mainImg.slick-slider{
	@if $type == pc{
		text-align: center;
		margin-bottom: 45px;
	}
	@if $type == sp{
		margin-bottom: 10px;
	}
}

/* move to common.css from here
.home_search{
	@if $type == pc{
		width: $site_w;
		margin: 0 auto;
	}
	@if $type == sp{
		background: #fff;
	}
}

.moreSearchWrap{
	@if $type == sp{
		margin: 20px 10px 0;
	}
}

.searchWrapIn_genre,
.searchWrapIn_other{
	& > label{
		cursor: default;
	}
	.searchWrapIn_bg{
		margin-top: 5px;
		background:#efefef;
		padding: 30px 30px 10px;
		border-radius: 3px;
		@if $type == sp{
			margin-top: 0;
			padding: 15px 15px 0px;
		}
		ul{
			overflow: hidden;
			li{
				font-size: 14px;
				float: left;
				margin-right: 35px;
				margin-bottom: 20px;
				position: relative;
				height: 20px;
				overflow: hidden;
				@if $type == sp{
					margin-right: 20px;
				}

				input[type="checkbox"]{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}
				label{
					display: block;
					padding-left: 27px;
					background:url("../images/common/checkbox.png") no-repeat 0 bottom;
					position: relative;
					z-index: 2;
					height: 20px;
					@if $type == sp{
						background:url("../images/sp/common/checkbox.png") no-repeat 0 bottom;
						background-size: 20px auto;
					}
				}
				input[type="checkbox"]:checked + label{
					background:url("../images/common/checkbox.png") no-repeat 0 0;
					@if $type == sp{
						background:url("../images/sp/common/checkbox.png") no-repeat 0 0;
						background-size: 20px auto;
					}
				}
			}
		}
	}
}

.btnWrap{
	text-align: center;
	padding-bottom: 60px;
	@if $type == sp{
		margin: 0 10px;
		padding: 10px 0px 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.sBtn{
		width: 275px;
		height: 44px;
		color: #fff;
		position: relative;
		background:#333333;
		border:none;
		border-radius: 3px;
		margin: 0 12px;
		font-size: 14px;
		cursor: pointer;
		@if $type == sp{
			flex-basis: 49%;
			width: 49%;
			margin: 0;
			font-size: 13px;
		}

		&:hover{
			background:$c_hov;
		}

		&:after{
			display: block;
			position: absolute;
			border-top: solid 1px #fff;
			border-right: solid 1px #fff;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			top: 50%;
			width: 12px;
			height: 12px;
			margin-top: -6px;
			right: 15px;
			@if $type == sp{
				display: inline-block;
				position: relative;
				top: -1px;
				right: 0;
				width: 8px;
				height: 8px;
				margin-left: 5px;
			}
		}
		&:disabled{
			background:#cccccc;
			color: #fff;
		}
	}
}


.search_toggle{
	display: inline-block;
	width: 175px;
	border-bottom: 1px solid $c_href;
	float: right;
	color: $c_href;
	margin-top: 15px;
	margin-left: -175px;
	font-size: 12px;
	position: relative;
	padding-left: 20px;
	padding-bottom: 10px;
	cursor: pointer;
	@if $type == sp{
		float: none;
		display: inline-block;
		width: auto;
		clear: both;
		margin: 0 auto;
		padding: 0 20px 10px 40px;
	}

	&:before{
		display: block;
		position: absolute;
		width: 10px;
		height: 10px;
		border-top: none;
		border-left: none;
		border-bottom: solid 1px $c_href;
		border-right: solid 1px $c_href;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-top: -12px;
		left: 20px ;
		content: "";
		top: 50%;
		@if $type == sp{
		}
	}
	&.open{
		&:before{
			border-bottom: none;
			border-right: none;
			border-top: solid 1px $c_href;
			border-left: solid 1px $c_href;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			margin-top: -8px;
			left: 20px ;
		}
	}
}
 move to common.css at to this point　*/

input[type = text].datepickerinput{
		background-image: url(../images/common/select_bt_cal.png);
		background-position: right top;
		background-repeat: no-repeat;
		height: 34px;
		@if $type == sp{
			font-size: 14px;
			background-image: url(../images/sp/common/select_bt_cal.png);
			background-size: 40px;
		}
}

#ui-datepicker-div{
	z-index: 10!important;
}